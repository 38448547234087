import { UseFormRegister } from "react-hook-form";
import { FormKey, FormValues } from "../../../models/FormValues";
import React from "react";

const FormInput = (props: {
  register: UseFormRegister<FormValues>;
  registrationName: FormKey;
  required: boolean;
  pattern?: RegExp;
  type: string;
  placeholder?: string;
}) => {
  return (
    <>
      <input
        id={props.registrationName}
        type={props.type}
        {...props.register(props.registrationName, {
          required: props.required,
          pattern: props.pattern,
        })}
        placeholder={props.placeholder}
      />
    </>
  );
};

export default FormInput;
