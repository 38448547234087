import React, { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { FormKey, FormValues } from "../../../models/FormValues";
import FormInput from "../FormInput/FormInput";

const CheckboxInput = (props: {
  register: UseFormRegister<FormValues>;
  formKey: FormKey;
  label: string | ReactNode;
  required: boolean;
  labelColor?: string;
}) => {
  return (
    <>
      <FormInput
        register={props.register}
        registrationName={props.formKey}
        required={props.required}
        type={"checkbox"}
      />
      <label
        htmlFor={props.formKey}
        style={{
          display: "inline",
          color: props.labelColor
            ? props.labelColor
            : "var(--text-color, black)",
        }}
      >
        {props.label}
      </label>
    </>
  );
};
export default CheckboxInput;
