import React, { useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormValues } from "../../../models/FormValues";
import { useTranslation } from "react-i18next";
import FormSection from "../FormSection/FormSection";
import { feedFactoryAxios } from "@feed-factory/feed-factory.helpers";
import classes from "../../../App.module.scss";
import {
  ButtonType,
  NavigationButton,
} from "@feed-factory/feed-factory.buttons";
import json5 from "json5";

const SERVER_URL = "https://smart-scrape-7cadtxmdta-ez.a.run.app?url=";

const SmartFillForm = (props: { formMethods: UseFormReturn<FormValues> }) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const urlInputRef = useRef<HTMLInputElement>(null);
  const errorParagraphRef = useRef<HTMLParagraphElement>(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const retrieveData = async (): Promise<string | null> => {
    setIsLoading(true);
    setHasError(false);

    const inputElement = urlInputRef.current;
    if (inputElement && !inputElement.checkValidity()) {
      setIsLoading(false);
      return null;
    }

    const url = inputElement?.value || "";
    if (!url) {
      setIsLoading(false);
      return null;
    }

    const response = await feedFactoryAxios
      .get(SERVER_URL + url)
      .catch((error) => {
        console.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

    const data = response?.data;
    if (!data) {
      return null;
    }

    let parsedData: any = {};
    // NOTE: Using sub-optimal custom data format to prevent issues with ChatGPT not escaping quotation marks properly.
    try {
      const keyValuePairs: string[] = data.split("////");
      for (const keyValuePair of keyValuePairs) {
        const [key, value] = keyValuePair.split("||||");
        if (value === "true") {
          parsedData[key] = true;
        } else if (value === "false") {
          parsedData[key] = false;
        } else {
          parsedData[key] = value;
        }
      }
    } catch (error: any) {
      console.error(error);
      setHasError(true);
      return null;
    }

    parsedData = json5.parse(JSON.stringify(parsedData));

    props.formMethods.reset({
      ...props.formMethods.getValues(),
      ...parsedData,
    });

    return url;
  };

  // TODO: Use React hook form for validation
  const checkButtonEnabled = () => {
    setIsButtonEnabled(
      !isLoading &&
        urlInputRef.current !== null &&
        urlInputRef.current.value !== "" &&
        urlInputRef.current.checkValidity()
    );
  };

  useEffect(() => {
    checkButtonEnabled();
  }, [isLoading]);

  return (
    <FormSection headingTitle={t("form:smartFillTitle")}>
      <div>
        {/*<label>...</label>*/}
        <p className={classes.categoryDescription}>
          {t("form:smartFillDescription")}
        </p>

        <input
          ref={urlInputRef}
          type="url"
          placeholder="https://..."
          onChange={checkButtonEnabled}
        />
        {hasError && (
          <div className={classes.formError} ref={errorParagraphRef}>
            {t("form:smartFillError")}
          </div>
        )}

        <div style={{ marginTop: "0.5rem", marginLeft: "0.3rem" }}>
          <NavigationButton
            disabled={!isButtonEnabled}
            action={retrieveData}
            type={ButtonType.Primary}
          >
            {isLoading ? "Laden..." : "Gegevens ophalen"}
          </NavigationButton>
        </div>
      </div>
    </FormSection>
  );
};
export default SmartFillForm;
