import React from "react";
import FormSection from "../Forms/FormSection/FormSection";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

const InstructionsText = () => {
  const { t, i18n } = useTranslation();

  return (
    <FormSection
      headingTitle={
        i18n.exists("form:introHeader")
          ? t("form:introHeader")
          : t("event.instructions")
      }
    >
      <ReactMarkdown linkTarget="_blank">{t("form:introTextMd")}</ReactMarkdown>
    </FormSection>
  );
};
export default InstructionsText;
