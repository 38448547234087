import classes from "../../App.module.scss";
import { LocationSelectionType } from "../../models/LocationSelectionType";
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormReturn,
} from "react-hook-form";
import LocationSelector from "@feed-factory/feed-factory.location-selector";
import { Lang } from "@feed-factory/feed-factory.models.lang";
import AddressWidget from "@feed-factory/feed-factory.address-widget";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../models/FormValues";

const LocationSelect = (props: {
  locationSelectionType: LocationSelectionType;
  control: Control<FormValues>;
  register: UseFormRegister<FormValues>;
  formMethods: UseFormReturn<FormValues>;
  filterOnUserOrganisations: string[];
  locationSelectParams?: { key: string; value: string }[];
}) => {
  const { t, i18n } = useTranslation();

  let locationSelectionWidget = <></>;

  if (
    props.locationSelectionType === LocationSelectionType.RegisteredLocation
  ) {
    locationSelectionWidget = (
      <Controller
        control={props.control}
        name="locationItem"
        rules={{
          required:
            props.locationSelectionType ===
            LocationSelectionType.RegisteredLocation,
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <LocationSelector
            value={value?.id || null}
            lang={i18n.language as Lang}
            filterOnUserOrganisations={props.filterOnUserOrganisations}
            onSelect={onChange}
            minimalCardDisplay
            myUserOrganisations={[]}
            additionalParams={props.locationSelectParams || undefined}
          />
        )}
        defaultValue={null}
      />
    );
  } else if (
    props.locationSelectionType === LocationSelectionType.ManualLocation
  ) {
    locationSelectionWidget = (
      <div className={classes.addressWidget}>
        <label>
          {i18n.exists("form:locationName")
            ? t("form:locationName")
            : t("event.locationName")}
          *
        </label>
        <input
          type="text"
          {...props.register("locationLabel", {
            required: true,
            maxLength: 256,
          })}
          className={classes.locationLabelInput}
        />
        <AddressWidget
          namespace="locationAddress."
          formMethods={props.formMethods}
          hideCountryField
          customTranslations={{
            street: i18n.exists("form:street") ? t("form:street") : undefined,
            housenr: i18n.exists("form:housenr")
              ? t("form:housenr")
              : undefined,
            zipcode: i18n.exists("form:zipcode")
              ? t("form:zipcode")
              : undefined,
            district: i18n.exists("form:district")
              ? t("form:district")
              : undefined,
            neighbourhood: i18n.exists("form:neighbourhood")
              ? t("form:neighbourhood")
              : undefined,
            city: i18n.exists("form:city") ? t("form:city") : undefined,
            country: i18n.exists("form:country")
              ? t("form:country")
              : undefined,
          }}
        />
      </div>
    );
  }

  return locationSelectionWidget;
};

export default LocationSelect;
