import React, { useEffect } from "react";
import classes from "../../../../App.module.scss";
import { Lang } from "@feed-factory/feed-factory.models.lang";
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput";
import { Controller, UseFormReturn } from "react-hook-form";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import { FormDetails } from "../../../../models/FormDetails";
import { FormValues } from "../../../../models/FormValues";
import FormSection from "../../FormSection/FormSection";

const EventDescriptionsForm = (props: {
  formDetails: FormDetails | null;
  formMethods: UseFormReturn<FormValues>;
  isStandalone?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const {
    control,
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = props.formMethods;

  let dutchLongDescriptionQuillRef: ReactQuill | null = null;
  let englishLongDescriptionQuillRef: ReactQuill | null = null;
  const updateQuillPlaceholder = (
    quillRef: ReactQuill | null,
    placeholder: string,
  ) => {
    if (quillRef) {
      quillRef.getEditor().root.dataset.placeholder = placeholder;
    }
  };
  useEffect(() => {
    const hidePlaceholder =
      props.formDetails?.hidePlaceholders &&
      props.formDetails.hidePlaceholders["longDescription"];
    updateQuillPlaceholder(
      dutchLongDescriptionQuillRef,
      hidePlaceholder ? "" : t("form.eventDescriptionPlaceholder"),
    );
    updateQuillPlaceholder(
      englishLongDescriptionQuillRef,
      hidePlaceholder ? "" : t("form.eventDescriptionPlaceholder"),
    );
  }, [t, dutchLongDescriptionQuillRef, englishLongDescriptionQuillRef]);

  const showEnglishShortDescription = watch("addEnglishShortDescription");
  const showEnglishLongDescription = watch("addEnglishLongDescription");

  // BELOW: Unused code to validate if English variant is mandatory
  const validateLongDescriptionEnglish = (
    longDescriptionEnglish: string,
  ): boolean => {
    if (!showEnglishLongDescription) {
      return true;
    }

    const longDescriptionEnglishHtmlStripped: string = longDescriptionEnglish
      ? longDescriptionEnglish.replace(/<(.|\n)*?>/g, "").trim()
      : "";
    const fieldIsEmpty: boolean =
      !longDescriptionEnglishHtmlStripped ||
      longDescriptionEnglishHtmlStripped === "";
    return !fieldIsEmpty; // If field is empty, return false
  };

  const eventDescriptionsForm = (
    <div className={classes.eventDescriptions}>
      <label>{t("event.title")} (*)</label>
      <p className={classes.categoryDescription}>
        {t("form:titleDescription")}
      </p>
      <input
        type="text"
        {...register("title", { required: true, maxLength: 256 })}
      />
      {errors.title && (
        <div className={classes.formError}>{t("form.eventTitleRequired")}</div>
      )}

      <label>
        {t("event.shortdescription")} {i18n.language !== Lang.NL && " (Dutch) "}
        (*)
      </label>
      <p className={classes.categoryDescription}>
        {t("form:shortDescription", {
          maxLength: props.formDetails?.maxLengthShortDescription || "∞",
        })}
      </p>
      <textarea
        placeholder={
          props.formDetails?.hidePlaceholders &&
          props.formDetails.hidePlaceholders["shortDescription"]
            ? ""
            : t("event.whoWhatWhereWhyHow")
        }
        {...register("shortDescription", {
          required: true,
          maxLength: props.formDetails?.maxLengthShortDescription || undefined,
        })}
      />
      {errors.shortDescription &&
        (errors.shortDescription.type === "maxLength" ? (
          <div className={classes.formError}>
            {t("form.shortDescriptionMaxLength", {
              maxLength: props.formDetails?.maxLengthShortDescription,
            })}
          </div>
        ) : (
          <div className={classes.formError}>
            {t("form.shortDescriptionRequired")}
          </div>
        ))}

      {!props.formDetails?.hideEnglishFields && (
        <div>
          <CheckboxInput
            formKey={"addEnglishShortDescription"}
            label={t("form:addEnglishShortDescription")}
            required={false}
            register={register}
          />
        </div>
      )}
      {showEnglishShortDescription && (
        <>
          <textarea
            placeholder={
              props.formDetails?.hidePlaceholders &&
              props.formDetails.hidePlaceholders["shortDescription"]
                ? ""
                : t("event.whoWhatWhereWhyHow")
            }
            {...register("shortDescriptionEnglish", {
              required: showEnglishShortDescription,
            })}
          />
          {errors.shortDescriptionEnglish && (
            <div className={classes.formError}>
              {t("form.shortDescriptionRequired")}
            </div>
          )}
        </>
      )}

      <label>
        {t("event.longdescription")}
        {i18n.language !== Lang.NL && " (Dutch) "}
      </label>
      <p className={classes.categoryDescription}>{t("form:longDescription")}</p>

      <Controller
        name={"longDescription"}
        control={control}
        defaultValue={""}
        rules={{
          required: false,
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <div className={classes.quillContainer}>
            <ReactQuill
              placeholder={
                props.formDetails?.hidePlaceholders &&
                props.formDetails.hidePlaceholders["longDescription"]
                  ? ""
                  : t("form.eventDescriptionPlaceholder")
              }
              onChange={(value) => {
                onChange(value);
              }}
              value={value}
              ref={(e) => {
                ref(e);
                dutchLongDescriptionQuillRef = e;
              }}
            />
          </div>
        )}
      />
      {errors.longDescription && (
        <div className={classes.formError}>
          {t("form.longDescriptionRequired")}
        </div>
      )}

      {!props.formDetails?.hideEnglishFields && (
        <div>
          <CheckboxInput
            formKey={"addEnglishLongDescription"}
            label={t("form:addEnglishLongDescription")}
            required={false}
            register={register}
          />
        </div>
      )}
      {showEnglishLongDescription && (
        <>
          <Controller
            name={"longDescriptionEnglish"}
            control={control}
            defaultValue={""}
            rules={{
              validate: (string, formValues) => {
                return validateLongDescriptionEnglish(string);
              },
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <div className={classes.quillContainer}>
                <ReactQuill
                  placeholder={
                    props.formDetails?.hidePlaceholders &&
                    props.formDetails.hidePlaceholders["longDescription"]
                      ? ""
                      : t("form.eventDescriptionPlaceholder")
                  }
                  onChange={(value) => {
                    onChange(value);
                  }}
                  value={value}
                  ref={(e) => {
                    ref(e);
                    englishLongDescriptionQuillRef = e;
                  }}
                />
              </div>
            )}
          />

          {errors.longDescriptionEnglish && (
            <div className={classes.formError}>
              {t("form.longDescriptionRequired")}
            </div>
          )}
        </>
      )}
    </div>
  );

  if (!props.isStandalone) {
    return eventDescriptionsForm;
  } else {
    return (
      <FormSection headingTitle={t("form:eventDescriptionsHeader")}>
        {eventDescriptionsForm}
      </FormSection>
    );
  }
};

export default EventDescriptionsForm;
