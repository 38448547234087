import React from "react";
import classes from "../../../../App.module.scss";
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { FormValues } from "../../../../models/FormValues";
import FormSection from "../../FormSection/FormSection";

const KidsFriendlyForm = (props: {
  formMethods: UseFormReturn<FormValues>;
  isStandalone?: boolean;
}) => {
  const { t } = useTranslation();
  const kidsFriendlyForm = (
    <div className={classes.kids}>
      <label>{t("form:targetAudience")}</label>
      <p className={classes.categoryDescription}>{t("form:kidsDescription")}</p>
      <div
        style={{
          marginBottom: "10px",
          marginTop: "5px",
          paddingLeft: "10px",
        }}
      >
        <CheckboxInput
          register={props.formMethods.register}
          formKey={"kids"}
          label={t("form:kidsCheckbox")}
          required={false}
        />
      </div>
    </div>
  );

  if (!props.isStandalone) {
    return kidsFriendlyForm;
  } else {
    return (
      <FormSection headingTitle={t("form:KidsFriendlyHeader")}>
        {kidsFriendlyForm}
      </FormSection>
    );
  }
};

export default KidsFriendlyForm;
