import classes from "../../../App.module.scss";
import LocationSelect from "../../LocationSelect/LocationSelect";
import { LocationSelectionType } from "../../../models/LocationSelectionType";
import React, { Dispatch, SetStateAction } from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormReturn,
} from "react-hook-form";
import { FormValues } from "../../../models/FormValues";
import { useTranslation } from "react-i18next";
import FormSection from "../FormSection/FormSection";

const WhereForm = (props: {
  register: UseFormRegister<FormValues>;
  formMethods: UseFormReturn<FormValues>;
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  locationSelectionType: LocationSelectionType;
  setLocationSelectionType: Dispatch<SetStateAction<LocationSelectionType>>;
  userOrganisation: string | undefined;
  locationSelectParams?: { key: string; value: string }[];
}) => {
  const { t, i18n } = useTranslation();

  return (
    <FormSection
      headingTitle={
        (i18n.exists("form:whereHeader")
          ? t("form:whereHeader")
          : t("event.where")) + " (*)"
      }
    >
      {i18n.exists("form:whereDescription") && (
        <p>{t("form:whereDescription")}</p>
      )}
      <div className={classes.locationSelectionWidget}>
        <LocationSelect
          locationSelectionType={props.locationSelectionType}
          control={props.control}
          register={props.register}
          formMethods={props.formMethods}
          filterOnUserOrganisations={
            props.userOrganisation ? [props.userOrganisation] : []
          }
          locationSelectParams={props.locationSelectParams || undefined}
        />
      </div>

      <button
        type="button"
        className={classes.toggleLocationSelectionTypeButton}
        onClick={() => {
          props.setLocationSelectionType(
            props.locationSelectionType === LocationSelectionType.ManualLocation
              ? LocationSelectionType.RegisteredLocation
              : LocationSelectionType.ManualLocation,
          );
        }}
      >
        {props.locationSelectionType === LocationSelectionType.ManualLocation
          ? t("form.selectRegisteredLocation")
          : t("form.manuallyEnterAddress")}
      </button>
    </FormSection>
  );
};
export default WhereForm;
