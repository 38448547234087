import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';
import classes from '../../../App.module.scss';
import MediaWidget from '@feed-factory/feed-factory.media-widget';
import { IntFileItem } from '@feed-factory/feed-factory.models.ndtrc';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../../../models/FormValues';
import FormSection from '../FormSection/FormSection';
import { FormDetails } from '../../../models/FormDetails';
import { Lang } from '@feed-factory/feed-factory.models.lang';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
const MediaForm = (props: {
  register: UseFormRegister<FormValues>;
  formMethods: UseFormReturn<FormValues>;
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  formDetails: FormDetails | null;
}) => {
  const { t, i18n } = useTranslation();
  const urlParamsStr: string = useLocation().search;
  const urlParams: URLSearchParams = new URLSearchParams(urlParamsStr);
  let formId: string | null = urlParams.get('id');
  if (!formId) {
    formId = 'iamsterdam';
  }
  const hideYoutube = props?.formDetails?.media?.hideYoutubeInput;

  return (
    <FormSection
      headingTitle={
        (i18n.exists('form:mediaHeader') ? t('form:mediaHeader') : 'Media') +
        ' (*)'
      }>
      <ReactMarkdown>
        {t('form:copyrights', {
          minHeight: props?.formDetails?.media?.requirements?.image?.minHeight,
        })}
      </ReactMarkdown>

      <Controller
        control={props.control}
        name='files'
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <div className={classes.mediaWidgetWrapper}>
            <MediaWidget
              mediaArray={value}
              onChange={onChange}
              hideYoutubeInput={hideYoutube}
              hideVimeoInput={true}
              imageFileType={props?.formDetails?.media?.imageFileType}
              imageUploadConditionsText={t('form:imgUploadConditions', {
                minHeight:
                  props?.formDetails?.media?.requirements?.image?.minHeight,
              })}
              requirements={props?.formDetails?.media?.requirements}
              showCopyrightAsRequired={!props.formDetails?.copyrightOptional}
              maxItems={props?.formDetails?.media?.maxItems || 0}
              primaryLang={(i18n.language as Lang) || 'nl'}
              standardImageLibrary={props?.formDetails?.standardImageLibrary}
            />
            {formId === 'iamsterdam' && (
              <ReactMarkdown>{t('form:mediaNotes')}</ReactMarkdown>
            )}
          </div>
        )}
        defaultValue={[]}
        rules={{
          required: !props.formDetails?.copyrightOptional,
          validate: (files: IntFileItem[]): string | boolean => {
            let hasNoImage = true;
            for (const file of files) {
              if (file.mediatype === 'photo') {
                hasNoImage = false;
                break;
              }
            }
            if (hasNoImage) {
              return 'form.imageRequired';
            }

            if (!props.formDetails?.copyrightOptional) {
              for (const file of files) {
                if (!file.copyright || !file.copyright.trim()) {
                  return 'form.copyrightInfoRequired';
                }
              }
            }

            return true;
          },
        }}
      />
      {props.errors.files &&
        ((props.errors.files as any).type === 'required' ? (
          <div className={classes.formError} style={{ marginTop: '10px' }}>
            {t('form.imageRequired')}
          </div>
        ) : (
          <div className={classes.formError} style={{ marginTop: '10px' }}>
            {t((props.errors.files as any).message)}
          </div>
        ))}
    </FormSection>
  );
};
export default MediaForm;
