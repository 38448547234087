import React from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import classes from "./ThankYouText.module.scss";
import { useHistory } from "react-router-dom";

const ThankYouText = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <ReactMarkdown linkTarget="_blank">
        {t("form:thankYouTextMd")}
      </ReactMarkdown>
      <button
        className={classes.ctaButton}
        onClick={() => {
          history.go(0);
        }}
      >
        {t("form:thankYouCTA")}
      </button>
    </>
  );
};
export default ThankYouText;
