import { FormValues } from "../models/FormValues";
import {
  Address,
  InternalEventItemModel,
  IntUrlDetails,
  MailDetails,
  PhoneDetails,
  PropertyDatatype,
  UrlServiceType,
} from "@feed-factory/feed-factory.models.ndtrc";
import { Lang } from "@feed-factory/feed-factory.models.lang";
import { LocationSelectionType } from "../models/LocationSelectionType";
import { getTagsForType } from "./getTagsForType";
import { FormDetails } from "../models/FormDetails";
import { TagsForTypeMap } from "../models/TagsForTypeMap";

export const convertFormToInternal = (
  formValues: FormValues,
  locationSelectionType: LocationSelectionType | null,
  formDetails?: FormDetails | null,
): InternalEventItemModel => {
  // @ts-ignore
  const eventItem: InternalEventItemModel = {
    entitytype: "EVENEMENT",
    id: null,
  };

  let contactUrls: {
    [id: string]: {
      url: string;
      urlServiceType: UrlServiceType;
    };
  } = {
    ticket: { url: formValues.ticketUrl, urlServiceType: "booking" },
    contact: { url: formValues.contactUrl, urlServiceType: "general" },
    facebook: {
      url: formValues.contactFacebook,
      urlServiceType: "socialmedia",
    },
    twitter: { url: formValues.contactTwitter, urlServiceType: "socialmedia" },
    instagram: {
      url: formValues.contactInstagram,
      urlServiceType: "socialmedia",
    },
  };

  contactUrls = Object.assign(
    {},
    ...Object.entries(contactUrls)
      .filter(([_, urlType]) => urlType && urlType.url && urlType.url !== "")
      .map(([id, urlType]) => ({ [id]: urlType })),
  );

  const contactInfoUrlDetails: IntUrlDetails[] = Object.keys(contactUrls).map(
    (id) => {
      return {
        id: id,
        url: contactUrls[id].url,
        targetLanguage: Lang.EN,
        urlServiceType: contactUrls[id].urlServiceType,
      };
    },
  );

  const mail: MailDetails = {
    email: formValues.contactMail,
  };
  const phone: PhoneDetails = {
    number: formValues.contactPhone,
  };
  eventItem.contactinfo = {
    urls: contactInfoUrlDetails,
    // @ts-ignore
    mail,
    phone,
  };

  // Disabled to avoid leaking personal information
  // eventItem.createdby = formValues.contactMailApplicant;
  // eventItem.acl = {
  //   readers: [],
  //   editors: [formValues.contactMailApplicant],
  //   managers: [],
  // };

  // Ensure that files array starts with an image
  if (formValues.files && formValues.files.length > 0) {
    const imageIndex = formValues.files.findIndex(
      (file) => file.mediatype === "photo",
    );
    if (imageIndex > 0) {
      const image = formValues.files[imageIndex];
      formValues.files[imageIndex] = formValues.files[0];
      formValues.files[0] = image;
    }
  }
  eventItem.files = formValues.files;

  // @ts-ignore
  eventItem.trcItemDetails = {
    nl: {
      title: formValues.title,
      shortdescription: formValues.shortDescription,
      longdescription: formValues.longDescription,
    },
    en: {
      title: formValues.title,
      shortdescription: formValues.shortDescriptionEnglish,
      longdescription: formValues.longDescriptionEnglish,
    },
  };

  eventItem.calendar = formValues.calendar;

  if (locationSelectionType === LocationSelectionType.ManualLocation) {
    if (formValues.locationAddress) {
      const address = formValues.locationAddress;
      address.country = "NL"; // Always assume NL for location in this form
      eventItem.location = {
        address: address,
        label: formValues.locationLabel,
      };
    }
  } else {
    if (formValues.locationItem) {
      const locTitle =
        formValues.locationItem.trcItemDetails[
          formValues.locationItem.translations?.primaryLanguage as Lang
        ].title;
      eventItem.location = {
        label: locTitle,
        locationItem: {
          text: locTitle,
          trcid: formValues.locationItem.trcid as string,
          id: formValues.locationItem.id as string,
        },
        address: formValues.locationItem.location?.address as Address,
      };
    }
  }

  // Single type selected
  if (formValues.types && !Array.isArray(formValues.types)) {
    formValues.types = [formValues.types];
  }

  if (formValues.priceElement) {
    const languageNoProblemKeyword = formValues.languageNoProblem ? "lnp" : "";
    const kidsKeyword = formValues.kids ? "jeugd" : "";
    const freeEventKeyword = formValues.priceElement.freeentrance ? "free" : "";

    let typeTags: string[] = [];
    if (formValues.types && formDetails?.tagsForType) {
      typeTags = formValues.types
        .map((typeId) => {
          return getTagsForType(
            typeId,
            formDetails.tagsForType as TagsForTypeMap,
          );
        })
        .flat();
      typeTags = [...new Set(typeTags)];
    }

    let categoryTags: string[] = [];
    if (formDetails?.tagsForCategory) {
      for (const [categoryId, tags] of Object.entries(
        formDetails?.tagsForCategory,
      )) {
        if (
          formValues.categoriesOfEvent &&
          formValues.categoriesOfEvent.includes(categoryId)
        ) {
          categoryTags = categoryTags.concat(tags);
        }
      }
    }

    const isAmsterdam750Event =
      formValues.showMotivation &&
      formDetails?.userOrganisation === "Amsterdam Marketing";
    const amsterdam750Keyword = isAmsterdam750Event ? "750amsterdam" : "";

    let keywordsList: string[] = [
      languageNoProblemKeyword,
      kidsKeyword,
      freeEventKeyword,
      amsterdam750Keyword,
    ];
    keywordsList = keywordsList.concat(typeTags);
    keywordsList = keywordsList.concat(categoryTags);
    keywordsList = keywordsList.concat(formValues.tagsOfEvent);
    if (formDetails?.tagsToAdd && formDetails.tagsToAdd.length > 0) {
      keywordsList = keywordsList.concat(formDetails.tagsToAdd);
    }
    keywordsList = keywordsList.filter(
      (keyword) => keyword !== "" && keyword !== undefined,
    );
    eventItem.keywords = keywordsList.join(";");
    eventItem.priceElements = [formValues.priceElement];
  }

  let typesCatIds: { catid: string }[] = [];

  if (formValues.types) {
    typesCatIds = formValues.types.map((type) => {
      if (type.startsWith("2.6.2")) {
        // Jazz and World Music both map to the same type (but internally have unique IDs in this form)
        type = "2.6.2";
      }

      if (type.startsWith("2.5.sub")) {
        // Allows for "Film" as both a main category and a sub-type mapping to the same ID
        type = "2.5";
      }
      return { catid: type };
    });

    // Remove duplicates (in case both Jazz AND World Music are selected)
    typesCatIds = typesCatIds.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.catid === value.catid),
    );
  }

  // If category selected already has three digits, assign it directly
  const categoryThreeDigitRegExp = /[0-9]+\.[0-9]+\.[0-9]+/g;
  const categoryHasThreeDigits = categoryThreeDigitRegExp.test(
    formValues.categoryOfEvent,
  );
  if (categoryHasThreeDigits) {
    typesCatIds.push({ catid: formValues.categoryOfEvent });
  }

  if (
    formValues.categoriesOfEvent &&
    formValues.categoriesOfEvent.includes("2.12")
  ) {
    // If exhibition, add the default type
    typesCatIds.push({ catid: "2.3.14" });
  }

  if (
    formValues.categoriesOfEvent &&
    formValues.categoriesOfEvent.includes("2.8")
  ) {
    // If sport, add the sport event type
    typesCatIds.push({ catid: "2.3.13" });
  }

  const youthFestivalSelected =
    formValues?.types && formValues.types.includes("2.4.5");
  const youthTheaterSelected =
    formValues?.types && formValues.types.includes("2.9.3");

  // get current url params
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);
  const formId = urlParams.get("id");

  if (
    (formValues.kids || youthFestivalSelected || youthTheaterSelected) &&
    formId
  ) {
    // Add the "jeugd" type
    typesCatIds.push({ catid: "2.3.25" });
  }

  // Check if form-specific fixed category is set
  if (formDetails?.fixedCategory) {
    typesCatIds.push({ catid: formDetails.fixedCategory });
  }

  eventItem.trcItemCategories = {
    types: [...typesCatIds], // NOT INCLUDED: { catid: formValues.categoryOfEvent }
  };

  if (formValues.contactMailApplicant) {
    // Send via memo
    // console.log(formValues.contactMailApplicant);
  }

  if (formValues.motivation) {
    // Send via memo
    // console.log(formValues.motivation);
  }

  if (formDetails) {
    eventItem.markers = formDetails.markers;
  }

  if (formValues.promotions) {
    eventItem.promotions = formValues.promotions;
  }

  const lang: Lang = formValues.formLanguage;
  const hasEnglish =
    formValues.addEnglishShortDescription ||
    formValues.addEnglishLongDescription;

  let availableLanguages = [lang];
  if (lang !== Lang.EN && hasEnglish) {
    availableLanguages.push(Lang.EN);
  }

  eventItem.translations = {
    primaryLanguage: lang,
    availableLanguages: availableLanguages,
  };

  eventItem.userorganisation = formDetails?.userOrganisation;

  return eventItem;
};
