import React from "react";
import classes from "../../../../App.module.scss";
import CheckboxInput from "../../../Inputs/CheckboxInput/CheckboxInput";
import { FormDetails } from "../../../../models/FormDetails";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { FormValues } from "../../../../models/FormValues";
import FormSection from "../../FormSection/FormSection";

const LanguageNoProblem = (props: {
  formDetails: FormDetails | null;
  formMethods: UseFormReturn<FormValues>;
  isStandalone?: boolean;
}) => {
  const { t } = useTranslation();
  const languageNoProblem = (
    <div className={classes.languageNoProblem}>
      {!props?.formDetails?.hideLanguageNoProblem && (
        <>
          {!props.isStandalone && <label>{t("form.languageNoProblem")}</label>}
          <p className={classes.categoryDescription}>
            {t("form:languageNoProblem")}
          </p>
          <div
            style={{
              marginBottom: "10px",
              marginTop: "5px",
              paddingLeft: "10px",
            }}
          >
            <CheckboxInput
              register={props.formMethods.register}
              formKey={"languageNoProblem"}
              label={t("form.languageNoProblem.label")}
              required={false}
            />
          </div>
        </>
      )}
    </div>
  );

  if (!props.isStandalone) {
    return languageNoProblem;
  } else {
    return (
      <FormSection headingTitle={t("form:languageNoProblemHeader")}>
        {languageNoProblem}
      </FormSection>
    );
  }
};

export default LanguageNoProblem;
