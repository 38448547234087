import React from "react";
import classes from "./Fireworks.module.scss";

// Source: https://codepen.io/hmaw/pen/qBEMLxV
const Fireworks = (props: {}) => (
  <div className={classes.pyro}>
    <div className={classes.before}></div>
    <div className={classes.after}></div>
  </div>
);

export default Fireworks;
