import React from "react";
import classes from "../../../../App.module.scss";
import ReactMarkdown from "react-markdown";
import FormInput from "../../../Inputs/FormInput/FormInput";
import { UseFormReturn } from "react-hook-form";
import { FormValues } from "../../../../models/FormValues";
import { useTranslation } from "react-i18next";
import FormSection from "../../FormSection/FormSection";

const ApplicantMailForm = (props: {
  formMethods: UseFormReturn<FormValues>;
  isStandalone?: boolean;
}) => {
  const { t, i18n } = useTranslation();

  const {
    register,
    formState: { errors },
  } = props.formMethods;

  const applicantMailForm = (
    <div className={classes.category}>
      <label>E-mail (*)</label>
      <div className={classes.categoryDescription}>
        <ReactMarkdown>{t("form:enterEmailDescription")}</ReactMarkdown>
      </div>
      <FormInput
        register={register}
        type="text"
        registrationName="contactMailApplicant"
        required={true}
        pattern={/^\S+@\S+$/i}
      />

      {errors.contactMailApplicant &&
        (errors.contactMailApplicant.type === "required" ? (
          <div className={classes.formError}>{t("form.emailRequired")}</div>
        ) : (
          <div className={classes.formError}>{t("form.emailInvalid")}</div>
        ))}
    </div>
  );

  if (!props.isStandalone) {
    return applicantMailForm;
  } else {
    return (
      <FormSection headingTitle={t("form:applicantMailHeader")}>
        {i18n.exists("form:applicantContactInfoDescription") && (
          <p>{t("form:applicantContactInfoDescription")}</p>
        )}
        {applicantMailForm}
      </FormSection>
    );
  }
};

export default ApplicantMailForm;
