import { IdWithLabel } from "../models/IdWithLabel";

export const getCategoryWithLabel = (
  categoryId: string,
  t: any,
  i18n: any
): IdWithLabel => {
  const getLabel = (categoryLabelString: string): string => {
    if (i18n.exists(`form:${categoryLabelString}`)) {
      return t(`form:${categoryLabelString}`);
    } else {
      return t(`${categoryLabelString}`);
    }
  };

  const categoryLabels: { [categoryId: string]: string } = {
    "2.3": getLabel("event.categories.events"),
    "2.6": getLabel("event.categories.music"),
    "2.9": getLabel("event.categories.theater"),
    "2.12": getLabel("event.categories.exhibitions"),
    "2.4": getLabel("event.categories.festivals"),
    "2.5": getLabel("event.categories.film"),
    "2.8": getLabel("event.categories.sport"),
    "2.11": getLabel("event.categories.location"),
    "2.5.2": getLabel("event.categories.film"),
    "2.3.14": getLabel("event.categories.exhibitions"),
    "2.3.28": getLabel("event.categories.nightlife"),
    "2.9.6": getLabel("event.categories.theater"),
    "2.9.2": getLabel("event.type.dance"),
    "2.9.1": getLabel("event.type.comedy"),
    "2.8.20": getLabel("event.type.outdoor"),
  };

  if (categoryId in categoryLabels) {
    return { id: categoryId, label: categoryLabels[categoryId] };
  }

  return { id: categoryId, label: "" };
};
