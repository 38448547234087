import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../models/FormValues";
import FormSection from "../FormSection/FormSection";
import { FormDetails } from "../../../models/FormDetails";
import { IdWithLabel } from "../../../models/IdWithLabel";
import CategoryForm from "./CategoryForm/CategoryForm";
import LanguageNoProblem from "./LanguageNoProblem/LanguageNoProblem";
import EventDescriptionsForm from "./EventDescriptionsForm/EventDescriptionsForm";
import KidsFriendlyForm from "./KidsFriendlyForm/KidsFriendlyForm";

const WhatForm = (props: {
  formMethods: UseFormReturn<FormValues>;
  typesForCategory: IdWithLabel[];
  formDetails: FormDetails | null;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <FormSection headingTitle={t("event.what")}>
      <CategoryForm
        formDetails={props.formDetails}
        formMethods={props.formMethods}
        typesForCategory={props.typesForCategory}
      />
      <LanguageNoProblem
        formDetails={props.formDetails}
        formMethods={props.formMethods}
      />

      {!props?.formDetails?.hideKidsToggle && (
        <KidsFriendlyForm formMethods={props.formMethods} />
      )}
      <EventDescriptionsForm
        formDetails={props.formDetails}
        formMethods={props.formMethods}
      />
    </FormSection>
  );
};
export default WhatForm;
