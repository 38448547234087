export enum FormSectionType {
  Introduction = 1,
  What,
  Motivation,
  Where,
  When,
  Promotions,
  Media,
  ContactInfo,
  Prices,
  Comment,
  ContactInfoApplicant,
  Category,
  LanguageNoProblem,
  EventDescriptions,
  KidsFriendlyForm,
  ApplicantMail,
  Consent,
}
