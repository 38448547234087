import React from "react";
import { Control, FieldErrors, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CalendarWidget from "@feed-factory/feed-factory.calendar-widget";
import { convertFormToInternal } from "../../../helpers/convertFormToInternal";
import globalClasses from "../../../App.module.scss";
import classes from "./WhenForm.module.scss";
import { LocationSelectionType } from "../../../models/LocationSelectionType";
import { FormValues } from "../../../models/FormValues";
import FormSection from "../FormSection/FormSection";
import { WhenFormConfig } from "../../../models/WhenFormConfig";

const WhenForm = (props: {
  formMethods: UseFormReturn<FormValues>;
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  locationSelectionType: LocationSelectionType | null;
  WhenFormConfig?: WhenFormConfig;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <FormSection
      headingTitle={
        (i18n.exists("form:whenHeader")
          ? t("form:whenHeader")
          : t("event.when")) + " (*)"
      }
    >
      {i18n.exists("form:whenDescription") && (
        <p>{t("form:whenDescription")}</p>
      )}
      <div className={classes.calendarWidget}>
        <CalendarWidget
          formMethods={props.formMethods}
          namespace={"calendar"}
          intItem={convertFormToInternal(
            props.formMethods.getValues(),
            props.locationSelectionType,
          )}
          localSave={(extraData: FormData | null = null) => {
            // ...
          }}
          hideCalendarTypes={[
            "NONE",
            "ALWAYSOPEN",
            "ONREQUEST",
            "OPENINGTIMES",
          ]}
          hideSecondWhenInPatternDate
          hideExceptions={true}
          hideGeneral={true}
          overrideSingleDateOptions={[
            { value: "premiere", label: "Première" },
            { value: "reprise", label: "Reprise" },
          ]}
          endTimesRequired={props.WhenFormConfig?.requireEndTime}
        />
      </div>

      {props.errors.calendar && (
        <div className={globalClasses.formError}>
          {i18n.exists("form:calendarRequired")
            ? t("form:calendarRequired")
            : t("form.calendarRequired")}
        </div>
      )}
    </FormSection>
  );
};
export default WhenForm;
