import CheckboxInput from "../../Inputs/CheckboxInput/CheckboxInput";
import CurrencyInput from "../../Inputs/CurrencyInput/CurrencyInput";
import classes from "../../../App.module.scss";
import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../models/FormValues";
import FormSection from "../FormSection/FormSection";

const PricesForm = (props: {
  register: UseFormRegister<FormValues>;
  formMethods: UseFormReturn<FormValues>;
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
}) => {
  const { t, i18n } = useTranslation();
  const freeEntrance = props.formMethods.watch("priceElement.freeentrance");

  return (
    <FormSection
      headingTitle={
        (i18n.exists("form:priceHeader")
          ? t("form:priceHeader")
          : t("item.prices")) + " (*)"
      }
    >
      <CheckboxInput
        register={props.register}
        formKey={"priceElement.freeentrance" as any}
        label={t("item.priceElements.freeEvent")}
        required={false}
      />
      <br />
      <p style={{ marginTop: "15px", fontWeight: "bold" }}>
        - {t("form:or")} -
      </p>
      {/* TODO: Style price inputs with "hasError" class */}
      {i18n.exists("form:priceDescription") && (
        <p>{t("form:priceDescription")}</p>
      )}
      <span>{t("form:from")}</span>{" "}
      <Controller
        control={props.formMethods.control}
        name={`priceElement.priceValue.from`}
        rules={{
          validate: (val) => {
            const valAsString = val?.toString();
            if (!valAsString || valAsString === "") {
              return false; // is required
            }

            const untilValue = props.formMethods.getValues(
              "priceElement.priceValue.until",
            ) as unknown as string;
            if (!untilValue) {
              return true;
            }

            if (
              untilValue &&
              Number.parseFloat(valAsString) > Number.parseFloat(untilValue)
            ) {
              return false; // cannot be higher than until value
            }
          },
        }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <CurrencyInput
            value={value?.toString() || ""}
            disabled={freeEntrance as boolean}
            onChange={(val) => {
              onChange(val);
              props.formMethods.trigger(`priceElement.priceValue.until`);
            }}
          />
        )}
      />
      <span>{t("form:to")}</span>
      <Controller
        // This form does not require an until price to be filled in
        control={props.formMethods.control}
        name={`priceElement.priceValue.until`}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <CurrencyInput
            value={value?.toString() || ""}
            disabled={freeEntrance as boolean}
            onChange={(val) => {
              onChange(val);
              props.formMethods.trigger(`priceElement.priceValue.from`);
            }}
          />
        )}
      />
      {!freeEntrance && props.errors.priceElement?.priceValue?.from && (
        <div className={classes.formError} style={{ textAlign: "left" }}>
          {t("item.priceElements.priceValue.fromError")}
        </div>
      )}
      {!freeEntrance && props.errors.priceElement?.priceValue?.until && (
        <div className={classes.formError} style={{ textAlign: "left" }}>
          {t("item.priceElements.priceValue.untilError")}
        </div>
      )}
    </FormSection>
  );
};
export default PricesForm;
