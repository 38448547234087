import React, { useEffect } from "react";
import classes from "./CheckboxSelector.module.scss";
import { IdWithLabel } from "../../../models/IdWithLabel";

const CheckboxSelector = (props: {
  name: string;
  options: IdWithLabel[];
  value: string[];
  onChange: (value: string[]) => void;
}) => {
  // On first run, force initial value setting to trigger validation
  useEffect(() => {
    // TODO: figure out why this does not run properly on first render
    // For now, require parent to set initial value
    props.onChange([]);
  }, []);

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      props.onChange([...props.value, e.currentTarget.value]);
    } else {
      props.onChange(props.value.filter((v) => v !== e.currentTarget.value));
    }
  };

  return (
    <div className={classes.CheckboxSelector}>
      {props.options.map((option) => (
        <div key={option.id} className={classes.optionContainer}>
          <input
            name={props.name}
            type="checkbox"
            value={option.id}
            checked={props.value?.includes(option.id)}
            id={option.id}
            onChange={onChecked}
          />
          <label htmlFor={option.id}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxSelector;
