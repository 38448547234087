import { FormDetails } from "../models/FormDetails";
import { FormSectionType } from "../models/FormSectionType";
import { Lang } from "@feed-factory/feed-factory.models.lang";

export const formDetails: { [id: string]: FormDetails } = {
  haarlem: {
    styling: {
      brandColor: "#AA8341",
      textColor: "#4A4A49",
      headerBgColor: "white",
      headerPaddingHorizontal: "0",
      headerPaddingVertical: "15px",
      headerFontSize: "1.75rem",
      headerTextColor: "#AA8341",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      sectionBgColor: "#F4F6F8",
      sectionPaddingVertical: "1.5rem",
      sectionPaddingHorizontal: "1.5rem",
      headerFont: "Josefin Sans, sans-serif",
      paragraphFont: "Lato, sans-serif",
    },
    userOrganisation: "Haarlem Marketing",
    markers: "toonopvisithaarlem;feed:01_p_haarlem_webform",
    meta: {
      title: "Submit event | Haarlem",
      faviconUrl: "favicon/haarlem.png",
    },
    maxLengthShortDescription: 200,
    tagsForType: {
      "2.3.1": ["festivals", "events"],
      "2.3.6": ["festivals", "culinaryevent"],
      "2.4.1": ["festivals", "theatrefestival"],
      "2.4.2": ["festivals", "filmfestival"],
      "2.4.5": ["festivals", "kids"],
      "2.3.8": ["festivals", "events"],
      "2.3.9": ["performingarts", "spokenword"],
      "2.4.3": ["festivals", "musicfestival"],
      "2.3.11": ["festivals", "events"],
      "2.3.13": ["events", "sportevent"],
      "2.4.4": ["festivals", "theatrefestival"],
      "2.3.15": ["performingarts", "workshop"],
      "2.6.1": ["music", "clubbing"],
      "2.6.2a": ["music", "jazz"],
      "2.6.2b": ["music", "worldmusic"],
      "2.6.3": ["music", "classicalmusic"],
      "2.6.8": ["music", ""],
      "2.6.5": ["music", "poprock"],
      "2.6.6": ["music", "rbsoul"],
      "2.9.1": ["performingarts", "comedy"],
      "2.9.2": ["performingarts", "dance"],
      "2.9.3": ["performingarts", "kids"],
      "2.9.4": ["performingarts", "musical11"],
      "2.9.5": ["performingarts", "opera"],
      "2.9.6": ["performingarts", "theatreproduction"],
      "2.9.8": ["performingarts", "theatreproduction"],
      "2.3.28": ["nightlife"],
    },
    tagsForCategory: {
      "2.12": ["exhibition"],
      "2.5": ["film"],
    },
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.2",
        "2.3.6",
        "2.7.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.15",
        "2.4.7",
        "2.3.28",
      ],
      "2.5": ["2.5.sub", "2.4.2"],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6", "2.4.3"],
      "2.9": ["2.9.1", "2.9.2", "2.9.3", "2.9.4", "2.9.5", "2.9.6", "2.4.4"],
      "2.12": [],
      "2.8": ["2.3.13"],
    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: false },
        { type: "ticketLink", required: false },
        { type: "facebook", required: false },
        { type: "x", required: false },
        { type: "instagram", required: false },
      ],
    },
  },
  iamsterdam: {
    styling: {
      brandColor: "#E3151A",
      paragraphFont: "Amsterdam Sans, Avenir, sans-serif",
      lineHeight: "1.5",
    },
    userOrganisation: "Amsterdam Marketing",
    markers: "toonopiamsterdamsite;feed:01_p_am_webform",
    meta: {
      title: "Submit event | I amsterdam",
      faviconUrl: "favicon/iamsterdam.ico",
      description:
        "Submit your event to the StartupAmsterdam Event Calendar, meant for online and in-person events, focused on and benefiting the Amsterdam Metropolitan Area’s startup and tech community.",
    },
    maxLengthShortDescription: 400,
    singleTypeSelect: true,
    tagsForType: {
      "2.3.1": ["festivals", "events"],
      "2.3.6": ["festivals", "culinaryevent"],
      "2.4.1": ["festivals", "theatrefestival"],
      "2.4.2": ["festivals", "filmfestival"],
      "2.4.5": ["festivals", "kids"],
      "2.3.8": ["festivals", "events"],
      "2.3.9": ["performingarts", "spokenword"],
      "2.4.3": ["festivals", "musicfestival"],
      "2.3.11": ["festivals", "events"],
      "2.3.13": ["events", "sportevent"],
      "2.4.4": ["festivals", "theatrefestival"],
      "2.3.15": ["performingarts", "workshop"],
      "2.6.1": ["music", "clubbing"],
      "2.6.2a": ["music", "jazz"],
      "2.6.2b": ["music", "worldmusic"],
      "2.6.3": ["music", "classicalmusic"],
      "2.6.8": ["music", ""],
      "2.6.5": ["music", "poprock"],
      "2.6.6": ["music", "rbsoul"],
      "2.9.1": ["performingarts", "comedy"],
      "2.9.2": ["performingarts", "dance"],
      "2.9.3": ["performingarts", "kids"],
      "2.9.4": ["performingarts", "musical"],
      "2.9.5": ["performingarts", "opera"],
      "2.9.6": ["performingarts", "theatreproduction"],
    },
    tagsForCategory: {
      "2.12": ["exhibition"],
      "2.5": ["film"],
    },
    media: {
      requirements: {
        image: {
          minHeight: 1000,
          orientation: ["landscape", "square"],
        },
      },
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.6",
        "2.4.1",
        "2.4.2",
        "2.4.5",
        "2.3.8",
        "2.3.9",
        "2.4.3",
        "2.3.11",
        "2.3.13",
        "2.4.4",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2a", "2.6.2b", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.3", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
    },
    hideKidsToggle: true,
    wizardStyleUse: true,
    customFormSections: [
      FormSectionType.Introduction,
      FormSectionType.Category,
      FormSectionType.LanguageNoProblem,
      FormSectionType.EventDescriptions,
      FormSectionType.Motivation,
      FormSectionType.Where,
      FormSectionType.When,
      FormSectionType.Media,
      FormSectionType.ContactInfo,
      FormSectionType.Prices,
      FormSectionType.ApplicantMail,
      FormSectionType.Consent,
    ],
    contactInfo: {
      customItems: [
        { type: "website", required: true },
        { type: "ticketLink", required: false },
        { type: "facebook", required: false },
        { type: "instagram", required: false },
      ],
    },
    whenInfo: {
      requireEndTime: true,
    },
    showAmsGarland: true,

    standardImageLibrary: [
      {
        trcid: null,
        main: null,
        copyright: "Rebekka Mell",
        filename: "Beurs_-_The_Next_Web_2021_-_credits_Rebekka_Mell.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b3ef3572600f640d3ccc/Beurs_-_The_Next_Web_2021_-_credits_Rebekka_Mell.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Beurs",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Beurs",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Cinekid",
        filename: "Cinekid_festival_Westerpark_credits_Cinekid.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b4403572600f640d3cce/Cinekid_festival_Westerpark_credits_Cinekid.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Film",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Film",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Jan de Ridder",
        filename:
          "Culinair_-_Kwaku_Summer_Festival_2023_-_credits_Jan_de_Ridder.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b4743572600f640d3cd0/Culinair_-_Kwaku_Summer_Festival_2023_-_credits_Jan_de_Ridder.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Culinair",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Culinair",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Bas Uterwijk",
        filename: "Cultureel_Festival_-Uitmarkt_-_credits_Bas_Uterwijk.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b48d3572600f640d3cd1/Cultureel_Festival_-Uitmarkt_-_credits_Bas_Uterwijk.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Cultureel Festival",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Cultureel Festival",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Jan de Ridder",
        filename: "Dance_-_Our_House_-_credits_Jan_de_Ridder.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b4c33572600f640d3cd4/Dance_-_Our_House_-_credits_Jan_de_Ridder.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Dance",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Dance",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Paul Ruisch",
        filename: "De_Parade_zweefmolen_credits_Paul_Ruisch.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b4e33572600f640d3cd5/De_Parade_zweefmolen_credits_Paul_Ruisch.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Kermis",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Kermis",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Jan de Ridder",
        filename: "Filmfestival_-_LAB111_cinema_Credits_Jan_de_Ridder.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b5093572600f640d3cd7/Filmfestival_-_LAB111_cinema_Credits_Jan_de_Ridder.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Filmfestival",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Filmfestival",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Melle Meivogel",
        filename:
          "Klassieke_Muziek_-_Muziekgebouw_aan__t_IJ_Asko_Scho_nberg_credits_Melle_Meivogel.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b51e3572600f640d3cd8/Klassieke_Muziek_-_Muziekgebouw_aan__t_IJ_Asko_Scho_nberg_credits_Melle_Meivogel.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Klassieke Muziek",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Klassieke Muziek",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Koen Smilde",
        filename:
          "Lezing_-_VoorleesExpress_voor_kids_OBA_credits_Koen_Smilde.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b5563572600f640d3cde/Lezing_-_VoorleesExpress_voor_kids_OBA_credits_Koen_Smilde.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Lezing",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Lezing",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Koen Smilde",
        filename:
          "Music_Festival_-_Talent_on_stage_24H_Zuidoost_-_credits_Koen_Smildejpg.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b5673572600f640d3cdf/Music_Festival_-_Talent_on_stage_24H_Zuidoost_-_credits_Koen_Smildejpg.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Music Festival",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Music Festival",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Jan de Ridder",
        filename:
          "Sportevenement_-_Kazerne_Reigersbos_-_credits_Jan_de_Ridder.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b5893572600f640d3ce0/Sportevenement_-_Kazerne_Reigersbos_-_credits_Jan_de_Ridder.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Sportevenement",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Sportevenement",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Jan de Ridder",
        filename:
          "Wereldmuziek_-_Muziekgebouw_aan__t_IJ_-_credits_Jan_de_Ridder.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b59e3572600f640d3ce2/Wereldmuziek_-_Muziekgebouw_aan__t_IJ_-_credits_Jan_de_Ridder.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Wereldmuziek",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Wereldmuziek",
            },
          ],
        },
      },
      {
        trcid: null,
        main: null,
        copyright: "Koen Smilde",
        filename:
          "Workshop_-_Painting_workshop_24H_West_event_-_credits_Koen_Smilde.jpg",
        hlink:
          "https://app.thefeedfactory.nl/api/assets/6634b5ba3572600f640d3ce3/Workshop_-_Painting_workshop_24H_West_event_-_credits_Koen_Smilde.jpg",
        filetype: "jpg",
        mediatype: "photo",
        targetLanguage: null,
        title: {
          label: "Workshop",
          titleTranslations: [
            {
              lang: Lang.NL,
              label: "Workshop",
            },
          ],
        },
      },
    ],
  },
  iamsterdampromo: {
    styling: {
      brandColor: "#E3151A",
    },
    userOrganisation: "Amsterdam Marketing",
    markers: "toonopiamsterdamsite;feed:01_p_am_webform",
    meta: {
      title: "Submit event (with promotion) | I amsterdam",
      faviconUrl: "favicon/iamsterdam.ico",
      description:
        "Submit your event to the StartupAmsterdam Event Calendar, meant for online and in-person events, focused on and benefiting the Amsterdam Metropolitan Area’s startup and tech community.",
    },
    maxLengthShortDescription: 400,
    singleTypeSelect: true,
    tagsForType: {
      "2.3.1": ["festivals", "events"],
      "2.3.6": ["festivals", "culinaryevent"],
      "2.4.1": ["festivals", "theatrefestival"],
      "2.4.2": ["festivals", "filmfestival"],
      "2.4.5": ["festivals", "kids"],
      "2.3.8": ["festivals", "events"],
      "2.3.9": ["performingarts", "spokenword"],
      "2.4.3": ["festivals", "musicfestival"],
      "2.3.11": ["festivals", "events"],
      "2.3.13": ["events", "sportevent"],
      "2.4.4": ["festivals", "theatrefestival"],
      "2.3.15": ["performingarts", "workshop"],
      "2.6.1": ["music", "clubbing"],
      "2.6.2a": ["music", "jazz"],
      "2.6.2b": ["music", "worldmusic"],
      "2.6.3": ["music", "classicalmusic"],
      "2.6.8": ["music", ""],
      "2.6.5": ["music", "poprock"],
      "2.6.6": ["music", "rbsoul"],
      "2.9.1": ["performingarts", "comedy"],
      "2.9.2": ["performingarts", "dance"],
      "2.9.3": ["performingarts", "kids"],
      "2.9.4": ["performingarts", "musical"],
      "2.9.5": ["performingarts", "opera"],
      "2.9.6": ["performingarts", "theatreproduction"],
    },
    tagsForCategory: {
      "2.12": ["exhibition"],
      "2.5": ["film"],
    },
    media: {
      requirements: {
        image: {
          minHeight: 1000,
          orientation: ["landscape", "square"],
        },
      },
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.6",
        "2.4.1",
        "2.4.2",
        "2.4.5",
        "2.3.8",
        "2.3.9",
        "2.4.3",
        "2.3.11",
        "2.3.13",
        "2.4.4",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2a", "2.6.2b", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.3", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
    },
    hideKidsToggle: true,
    customFormSections: [
      FormSectionType.Introduction,
      FormSectionType.What,
      FormSectionType.Where,
      FormSectionType.When,
      FormSectionType.Promotions,
      FormSectionType.Media,
      FormSectionType.ContactInfo,
      FormSectionType.Prices,
      FormSectionType.ContactInfoApplicant,
    ],
  },
  zuidoost: {
    styling: {
      brandColor: "#1A983A",
    },
    userOrganisation: "Zuidoostcity, amsterdam marketing",
    markers: "toonopzuidoost;feed:01_p_amzuidoost_webform",
    meta: {
      title: "Submit event | Zuidoost",
      faviconUrl: "favicon/zuidoost.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {
      "2.3.1": ["festivals", "events"],
      "2.3.6": ["festivals", "culinaryevent", "events"],
      "2.4.1": ["festivals", "theatrefestival", "events"],
      "2.4.2": ["festivals", "events", "filmfestival"],
      "2.4.5": ["festivals", "kids", "events"],
      "2.3.8": ["festivals", "events"],
      "2.3.9": ["performingarts", "spokenword", "festivals", "events"],
      "2.4.3": ["festivals", "musicfestival", "events"],
      "2.3.11": ["festivals", "events"],
      "2.3.13": ["sportevent", "festivals", "events"],
      "2.4.4": ["festivals", "theatrefestival", "events"],
      "2.3.15": ["performingarts", "workshop", "festivals", "events"],
      "2.5.2": ["film"],
      "2.6.1": ["music", "clubbing", "concert"],
      "2.6.2a": ["music", "jazz", "concert"],
      "2.6.2b": ["music", "worldmusic", "concert"],
      "2.6.3": ["music", "classicalmusic", "concert"],
      "2.6.8": ["music", "concert"],
      "2.6.5": ["music", "poprock", "concert"],
      "2.6.6": ["music", "rbsoul", "concert"],
      "2.9.1": ["performingarts", "comedy", "voorstelling", "tentoonstelling"],
      "2.9.2": ["performingarts", "dance", "voorstelling", "tentoonstelling"],
      "2.9.3": ["performingarts", "kids", "voorstelling", "tentoonstelling"],
      "2.9.4": ["performingarts", "musical", "voorstelling", "tentoonstelling"],
      "2.9.5": ["performingarts", "opera", "voorstelling", "tentoonstelling"],
      "2.9.6": [
        "performingarts",
        "theatreproduction",
        "voorstelling",
        "tentoonstelling",
      ],
      "2.9.8": [
        "performingarts",
        "theatreproduction",
        "voorstelling",
        "tentoonstelling",
      ],
    },
    tagsForCategory: {
      "2.12": ["exhibition"],
    },
    media: {
      requirements: {
        image: {
          minHeight: 1000,
          orientation: ["landscape", "square"],
        },
      },
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.6",
        "2.4.1",
        "2.4.2",
        "2.4.5",
        "2.3.8",
        "2.3.9",
        "2.4.3",
        "2.3.11",
        "2.3.13",
        "2.4.4",
        "2.3.15",
        "2.5.2",
      ],
      "2.6": ["2.6.1", "2.6.2a", "2.6.2b", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.3", "2.9.4", "2.9.5", "2.9.6", "2.9.8"],
      "2.12": [],
    },
    hideKidsToggle: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: false },
        { type: "ticketLink", required: false },
        { type: "facebook", required: false },
        { type: "x", required: false },
        { type: "instagram", required: false },
      ],
    },
  },
  rotterdam: {
    styling: {
      brandColor: "#ffff5d",
      textColor: "#4A4A49",
      headerBgColor: "white",
      letterSpacing: "0.1rem",
      headerPaddingHorizontal: "0",
      headerPaddingVertical: "15px",
      headerFontSize: "1.75rem",
      headerTextColor: "#dbb7fd",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      sectionBgColor: "#F4F6F8",
      sectionPaddingVertical: "1.5rem",
      sectionPaddingHorizontal: "1.5rem",
      headerFont: "Forma-DJR-Bold, sans-serif",
      paragraphFont: "Forma-DJR, sans-serif",
    },
    userOrganisation: "Rotterdam Marketing",
    markers: "toonoprotterdamuitagenda",
    meta: {
      title: "Submit event | Rotterdam Marketing",
      faviconUrl: "favicon/uitagendaV2.png",
    },
    tagsForType: {
      "2.3.1": [],
      "2.3.6": [],
      "2.4.1": [],
      "2.4.2": [],
      "2.4.5": [],
      "2.3.8": [],
      "2.3.9": [],
      "2.4.3": [],
      "2.3.11": [],
      "2.3.13": [],
      "2.4.4": [],
      "2.3.15": [],
      "2.6.1": [],
      "2.6.2a": [],
      "2.6.2b": [],
      "2.6.3": [],
      "2.6.8": [],
      "2.6.5": [],
      "2.6.6": [],
      "2.9.1": [],
      "2.9.2": [],
      "2.9.3": [],
      "2.9.4": [],
      "2.9.5": [],
      "2.9.6": [],
      "2.9.8": [],
    },
    tagsForCategory: {
      "2.12": ["exhibition"],
      "2.5": ["film"],
    },
    media: {
      hideYoutubeInput: true,
      maxItems: 1,
      imageFileType: "jpegOrPngOrWebp",
    },
    hideLanguageNoProblem: true,
    hideLanguageSelect: true,
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.6",
        "2.7.2",
        "2.5.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.13",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
      "2.4": ["2.4.1", "2.4.8", "2.4.2", "2.4.3", "2.4.4"],
      "2.3.28": [],
    },
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    allowSelectingMultipleCategories: true,
  },
  congresagenda: {
    styling: {
      brandColor: "#003b49",
    },
    userOrganisation: "",
    markers: "toonopcongresagenda",
    fixedCategory: "2.3.4",
    meta: {
      title: "Meld congres aan | Congresagenda",
      faviconUrl: "favicon/feed-factory.ico",
    },
    maxLengthShortDescription: 300,
    media: {
      hideYoutubeInput: true,
      imageFileType: "jpegOrPngOrWebp",
    },
    tags: [
      {
        id: "cat:zorg",
        label: "Zorg",
      },
      {
        id: "cat:mens&maatschappij",
        label: "Mens & Maatschappij",
      },
      {
        id: "cat:business&management",
        label: "Business & Management",
      },
      {
        id: "cat:cultuur&toerisme",
        label: "Cultuur & Toerisme",
      },
      {
        id: "cat:wetenschap",
        label: "Wetenschap",
      },
    ],
    locationSelectParams: [
      {
        key: "types",
        value: "1.1.1",
      },
    ],
    hideLanguageNoProblem: true,
    hideLanguageSelect: true,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    hideKidsToggle: true,
  },
  cultureleuitagenda: {
    styling: {
      brandColor: "#0f4c5c",
    },
    userOrganisation: "",
    markers: "toonopcultureleuitagenda",
    meta: {
      title: "Meld evenement aan | Culturele Uitagenda",
      faviconUrl: "favicon/feed-factory.ico",
    },
    maxLengthShortDescription: 300,
    media: {
      hideYoutubeInput: true,
      imageFileType: "jpegOrPngOrWebp",
    },
    categories: {
      "2.6": ["2.6.9", "2.6.10", "2.6.3", "2.6.4", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.3", "2.9.4", "2.9.5", "2.9.6"],
      "2.4": ["2.4.1", "2.4.2", "2.4.3", "2.4.4", "2.4.5", "2.4.7", "2.4.8"],
      "2.5.2": [],
      "2.3.14": [],
    },
    hideLanguageNoProblem: true,
    hideLanguageSelect: true,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    hideKidsToggle: true,
  },
  apeldoorn: {
    styling: {
      brandColor: "#42cf4a",
      textColor: "#11190C",
      headerTextColor: "#11190C",
      borderRadius: "0px",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      paragraphFont:
        "Fivo Sans, Helvetica Neue Bold, HelveticaNeue-Bold, Helvetica Bold, Helvetica-Bold, Arial Bold, Arial-BoldMT, sans-serif",
    },
    hideLanguageSelect: true,
    hideEnglishFields: true,
    userOrganisation: "apeldoornpartners",
    markers: "toonopinapeldoorn",
    meta: {
      title: "Invoeren van je evenement | Uit in Apeldoorn",
      faviconUrl: "favicon/apeldoorn.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {},
    tagsForCategory: {},
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.6": [],
      "2.5": [],
      "2.9.6": [],
      "2.9.2": [],
      "2.9.1": [],
      "2.3": [],
      "2.8.20": [],
      "2.8": [],
      "2.3.28": [],
    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    copyrightOptional: true,
    showCommentField: true,
    hideLanguageNoProblem: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: true },
      ],
    },
  },
  bezoekbergen: {
    styling: {
      brandColor: "#004c6d",
      textColor: "#1a1a1a",
      brandColorContrast: "#f8f8f8",

      headerTextColor: "#f8f8f8",
      borderRadius: "0px",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      paragraphFont:
        "Inter, sans-serif",
    },
    hideLanguageSelect: true,
    hideEnglishFields: true,
    userOrganisation: "bezoekbergen",
    markers: "toonopbezoekbergen",
    meta: {
      title: "Invoeren van je evenement | Uit in Bergen",
      faviconUrl: "favicon/regioalkmaar.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {},
    tagsForCategory: {},
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.2",
        "2.3.6",
        "2.7.2",
        "2.5.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.13",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
      "2.4": ["2.4.1", "2.4.8", "2.4.2", "2.4.3", "2.4.4"],
      "2.3.28": [],
      "2.11": ["2.11.2", "2.11.5"]
    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    copyrightOptional: true,
    showCommentField: true,
    hideLanguageNoProblem: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: true },
      ],
    },
  },
  castricumvoorliefhebbers: {
    styling: {
      brandColor: "#3d5468",
      textColor: "#1a1a1a",
      headerTextColor: "#f8f8f8",
      brandColorContrast: "#f8f8f8",
      borderRadius: "0px",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      paragraphFont:
        "Weissenhof Grotesk, sans-serif",
    },
    hideLanguageSelect: true,
    hideEnglishFields: true,
    userOrganisation: "castricumvoorliefhebbers",
    markers: "toonopcastricum",
    meta: {
      title: "Invoeren van je evenement | Uit in Castricum",
      faviconUrl: "favicon/regioalkmaar.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {},
    tagsForCategory: {},
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.2",
        "2.3.6",
        "2.7.2",
        "2.5.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.13",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
      "2.4": ["2.4.1", "2.4.8", "2.4.2", "2.4.3", "2.4.4"],
      "2.3.28": [],
      "2.11": ["2.11.2", "2.11.5"]

    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    copyrightOptional: true,
    showCommentField: true,
    hideLanguageNoProblem: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: true },
      ],
    },
  },
  egmond: {
    styling: {
      brandColor: "#960a18",
      brandColorContrast: "#f8f8f8",
      textColor: "#1a1a1a",
      headerTextColor: "#f8f8f8",
      borderRadius: "0px",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      paragraphFont:
        "Montserrat, sans-serif",
    },
    hideLanguageSelect: true,
    hideEnglishFields: true,
    userOrganisation: "egmond",
    markers: "toonopegmond",
    meta: {
      title: "Invoeren van je evenement | Uit in Egmond",
      faviconUrl: "favicon/regioalkmaar.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {},
    tagsForCategory: {},
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.2",
        "2.3.6",
        "2.7.2",
        "2.5.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.13",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
      "2.4": ["2.4.1", "2.4.8", "2.4.2", "2.4.3", "2.4.4"],
      "2.3.28": [],
      "2.11": ["2.11.2", "2.11.5"]
    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    copyrightOptional: true,
    showCommentField: true,
    hideLanguageNoProblem: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: true },
      ],
    },
  },
  duindorpschoorl: {
    styling: {
      brandColor: "#295842",
      textColor: "#1a1a1a",
      brandColorContrast: "#f8f8f8",
      headerTextColor: "#f8f8f8",
      borderRadius: "0px",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      paragraphFont:
        "TT Teds, sans-serif",
    },
    hideLanguageSelect: true,
    hideEnglishFields: true,
    userOrganisation: "duindorpschoorl",
    markers: "toonopduindorpschoorl",
    meta: {
      title: "Invoeren van je evenement | Uit in Schoorl",
      faviconUrl: "favicon/regioalkmaar.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {},
    tagsForCategory: {},
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.2",
        "2.3.6",
        "2.7.2",
        "2.5.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.13",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
      "2.4": ["2.4.1", "2.4.8", "2.4.2", "2.4.3", "2.4.4"],
      "2.3.28": [],
      "2.11": ["2.11.2", "2.11.5"]
    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    copyrightOptional: true,
    showCommentField: true,
    hideLanguageNoProblem: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: true },
      ],
    },
  },
  ontdekdijkenwaard: {
    styling: {
      brandColor: "#314353",
      textColor: "#1a1a1a",
      brandColorContrast: "#f8f8f8",
      headerTextColor: "#f8f8f8",
      borderRadius: "0px",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      paragraphFont:
        "Montserrat, sans-serif",
    },
    hideLanguageSelect: true,
    hideEnglishFields: true,
    userOrganisation: "ontdekdijkenwaard",
    markers: "toonopontdekdijkenwaard",
    meta: {
      title: "Invoeren van je evenement | Uit in Dijk en Waard",
      faviconUrl: "favicon/regioalkmaar.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {},
    tagsForCategory: {},
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.2",
        "2.3.6",
        "2.7.2",
        "2.5.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.13",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
      "2.4": ["2.4.1", "2.4.8", "2.4.2", "2.4.3", "2.4.4"],
      "2.3.28": [],
      "2.11": ["2.11.2", "2.11.5"]
    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    copyrightOptional: true,
    showCommentField: true,
    hideLanguageNoProblem: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: true },
      ],
    },
  },
  ontdekregioalkmaar: {
    styling: {
      brandColor: "#004c6d",
      textColor: "#1a1a1a",
      brandColorContrast: "#f8f8f8",
      headerTextColor: "#f8f8f8",
      borderRadius: "0px",
      sectionBorder: "none",
      sectionBoxShadow: "none",
      paragraphFont:
        "Montser, sans-serif",
    },
    hideLanguageSelect: true,
    hideEnglishFields: true,
    userOrganisation: "ontdekregioalkmaar",
    markers: "toonopontdekregioalkmaar",
    meta: {
      title: "Invoeren van je evenement | Uit in Alkmaar",
      faviconUrl: "favicon/regioalkmaar.png",
    },
    maxLengthShortDescription: 400,
    tagsForType: {},
    tagsForCategory: {},
    media: {
      imageFileType: "jpeg",
    },
    categories: {
      "2.3": [
        "2.3.1",
        "2.3.2",
        "2.3.6",
        "2.7.2",
        "2.5.2",
        "2.3.8",
        "2.3.9",
        "2.3.10",
        "2.3.11",
        "2.3.12",
        "2.3.13",
        "2.3.15",
      ],
      "2.6": ["2.6.1", "2.6.2", "2.6.3", "2.6.8", "2.6.5", "2.6.6"],
      "2.9": ["2.9.1", "2.9.2", "2.9.4", "2.9.5", "2.9.6"],
      "2.12": [],
      "2.4": ["2.4.1", "2.4.8", "2.4.2", "2.4.3", "2.4.4"],
      "2.3.28": [],
      "2.11": ["2.11.2", "2.11.5"]
    },
    hideKidsToggle: false,
    hidePlaceholders: {
      shortDescription: true,
      longDescription: true,
    },
    copyrightOptional: true,
    showCommentField: true,
    hideLanguageNoProblem: true,
    contactInfo: {
      customItems: [
        { type: "phoneNumber", required: false },
        { type: "email", required: false },
        { type: "website", required: true },
      ],
    },
  },
};
