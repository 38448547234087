import React from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormReturn,
} from "react-hook-form";
import { FormValues } from "../../../models/FormValues";
import { useTranslation } from "react-i18next";
import FormSection from "../FormSection/FormSection";
import ConsentForm from "./ConsentForm/ConsentForm";
import ApplicantMailForm from "./ApplicantMailForm/ApplicantMailForm";

const ContactInfoApplicantForm = (props: {
  register: UseFormRegister<FormValues>;
  formMethods: UseFormReturn<FormValues>;
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <FormSection
      headingTitle={
        (i18n.exists("form:applicantContactInfoHeader")
          ? t("form:applicantContactInfoHeader")
          : t("event.applicantContactInfo")) + " (*)"
      }
    >
      {i18n.exists("form:applicantContactInfoDescription") && (
        <p>{t("form:applicantContactInfoDescription")}</p>
      )}
      <ApplicantMailForm formMethods={props.formMethods} />
      <ConsentForm formMethods={props.formMethods} />
    </FormSection>
  );
};
export default ContactInfoApplicantForm;
