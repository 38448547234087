import React, { useEffect } from "react";
import classes from "./RadioButtonSelector.module.scss";
import { IdWithLabel } from "../../../models/IdWithLabel";

const RadioButtonSelector = (props: {
  name: string;
  options: IdWithLabel[];
  value: string | null;
  onChange: (value: string | null) => void;
}) => {
  // On first run, force initial value setting to trigger validation
  useEffect(() => {
    props.onChange(null);
  }, []);

  return (
    <div className={classes.RadioButtonSelector}>
      {props.options.map((option) => (
        <div key={option.id} className={classes.optionContainer}>
          <input
            name={props.name}
            type="radio"
            value={option.id}
            id={option.id}
            onChange={(e) => {
              props.onChange(e.currentTarget.value || null);
            }}
          />
          <label htmlFor={option.id}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

export default RadioButtonSelector;
