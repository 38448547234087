import React from 'react';
import classes from '../../../../App.module.scss';
import CheckboxInput from '../../../Inputs/CheckboxInput/CheckboxInput';
import ReactMarkdown from 'react-markdown';
import { UseFormReturn } from 'react-hook-form';
import { FormValues } from '../../../../models/FormValues';
import { useTranslation } from 'react-i18next';
import FormSection from '../../FormSection/FormSection';

const ConsentForm = (props: {
  formMethods: UseFormReturn<FormValues>;
  isStandalone?: boolean;
}) => {
  const { t, i18n } = useTranslation();

  const permissionToUseImagesAndTexts = props.formMethods.watch(
    'permissionToUseImagesAndTexts'
  );
  const consent = (
    <>
      <div className={classes.category}>
        {i18n.exists('form:consentDescription') && (
          <ReactMarkdown>{t('form:consentDescription')}</ReactMarkdown>
        )}
        <CheckboxInput
          register={props.formMethods.register}
          formKey={'permissionToUseImagesAndTexts'}
          label={
            <ReactMarkdown linkTarget='_blank' className={'inlineMarkdown'}>
              {t('form:permissionToUseImagesTexts')}
            </ReactMarkdown>
          }
          required={true}
        />
        {!permissionToUseImagesAndTexts && (
          <div className={classes.formError}>
            {t('form.permissionRequired')}
          </div>
        )}
      </div>

      <div className={classes.category} style={{ marginTop: '10px' }}>
        <CheckboxInput
          register={props.formMethods.register}
          formKey={'saveValuesForNextEvent'}
          label={t('form.saveForNextEvent')}
          required={false}
          labelColor={'green'}
        />
      </div>
    </>
  );

  if (!props.isStandalone) {
    return consent;
  } else {
    return (
      <FormSection headingTitle={t('form:consentHeader')}>
        {consent}
      </FormSection>
    );
  }
};

export default ConsentForm;
