export const loadMailerLite = (callback: () => any) => {
  const existingScript = document.getElementById("mailerLite");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "/mailerLiteUniversal.js";
    script.id = "mailerLite";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
