import { TagsForTypeMap } from "../models/TagsForTypeMap";

export const getTagsForType = (
  typeId: string,
  tagsForType: TagsForTypeMap
): string[] => {
  if (!tagsForType) {
    return [];
  }

  if (typeId in tagsForType) {
    return tagsForType[typeId];
  }

  return [];
};
