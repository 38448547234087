import { FormDetails } from "../models/FormDetails";
import { IdWithLabel } from "../models/IdWithLabel";
import { getCategoryById } from "@feed-factory/feed-factory.helpers";

export const getTypesForCategory = (
  categoryId: string,
  formDetails: FormDetails | null,
  t: any,
  i18n: any,
): IdWithLabel[] => {
  if (!formDetails || !formDetails.categories) {
    return [];
  }

  const getLabel = (categoryLabelString: string): string => {
    if (i18n.exists(`form:${categoryLabelString}`)) {
      return t(`form:${categoryLabelString}`);
    } else {
      return t(`${categoryLabelString}`);
    }
  };

  const categoryTypes: { [typeId: string]: string } = {
    "2.3.1": getLabel("event.type.fair"),
    "2.3.2": getLabel("event.type.braderie"),
    "2.7.2": getLabel("event.type.excursion"),
    "2.3.12": getLabel("event.type.guidedTour"),
    "2.4.7": getLabel("event.type.festival"),
    "2.3.3": getLabel("event.type.circus"),
    "2.3.6": getLabel("event.type.culinary"),
    "2.5.2": getLabel("event.type.film"),
    "2.4.1": getLabel("event.type.culturalFestival"),
    "2.4.2": getLabel("event.type.filmFestival"),
    "2.4.5": getLabel("event.type.youthFestival"),
    "2.3.8": getLabel("event.type.funFair"),
    "2.3.9": getLabel("event.type.lectureDebate"),
    "2.4.3": getLabel("event.type.musicFestival"),
    "2.3.10": getLabel("event.type.market"),
    "2.3.11": getLabel("event.type.openDay"),
    "2.3.13": getLabel("event.type.sportsEvent"),
    "2.4.4": getLabel("event.type.theatreFestival"),
    "2.3.15": getLabel("event.type.workshop"),
    "2.6.1": getLabel("event.type.danceMusic"),
    "2.6.2": getLabel("event.type.jazzWorldMusic"),
    "2.6.2a": getLabel("event.type.jazz"),
    "2.6.2b": getLabel("event.type.worldMusic"),
    "2.6.3": getLabel("event.type.classicalMusic"),
    "2.6.8": getLabel("event.type.musicOther"),
    "2.6.5": getLabel("event.type.popRock"),
    "2.6.6": getLabel("event.type.RBSoul"),
    "2.9.1": getLabel("event.type.comedy"),
    "2.9.2": getLabel("event.type.dance"),
    "2.9.3": getLabel("event.type.youthTheatre"),
    "2.9.4": getLabel("event.type.musical"),
    "2.9.5": getLabel("event.type.opera"),
    "2.9.6": getLabel("event.type.theatre"),
    "2.9.8": getLabel("event.type.theatreOther"),

    "2.4.8": getLabel("event.type.danceFestival"),
    "2.5.sub": getLabel("event.type.film"),

    "2.3.28": getLabel("event.type.nightlife"),
  };

  if (!formDetails.categories || !formDetails.categories[categoryId]) {
    return [];
  }

  return formDetails.categories[categoryId].map((typeId) => {
    if (!Object.keys(categoryTypes).includes(typeId)) {
      // Try as fallback the Dutch-only category helper in ff-gui
      return { id: typeId, label: getCategoryById(typeId) || "?" };
    }
    return { id: typeId, label: categoryTypes[typeId] };
  });
};
