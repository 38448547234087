import {
  feedFactoryAxios,
  internalToExternal,
} from "@feed-factory/feed-factory.helpers";
import { InternalEventItemModel } from "@feed-factory/feed-factory.models.ndtrc";

export const postNewEvent = (
  eventItem: InternalEventItemModel,
  id: string | null
) => {
  return feedFactoryAxios.post("/events", internalToExternal(eventItem));
};
