import React from "react";
import classes from "./FormSection.module.scss";

const FormSection = (props: { children: any; active: boolean }) => {
  return (
    <div
      className={[props.active ? classes.active : "", classes.FormSection].join(
        " ",
      )}
    >
      {props.children}
    </div>
  );
};

export default FormSection;
