import React, { useEffect, useState } from 'react';
import classes from './App.module.scss';
import { FieldErrors, useForm } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
import { Lang } from '@feed-factory/feed-factory.models.lang';
import { useLocation, withRouter } from 'react-router-dom';
import { postNewEvent } from './helpers/postNewEvent';
import {
  externalToInternal,
  getEmptyExternalItem,
  Twemoji,
} from '@feed-factory/feed-factory.helpers';
import { FormValues } from './models/FormValues';
import { LocationSelectionType } from './models/LocationSelectionType';
import {
  ExternalItemModel,
  ExternalEventItemModel,
  ExternalLocationItemModel,
  InternalEventItemModel,
  InternalItemModel,
} from '@feed-factory/feed-factory.models.ndtrc';
import 'quill-paste-smart';
import InstructionsText from './components/InstructionsText/InstructionsText';
import { useTranslation } from 'react-i18next';
import { convertFormToInternal } from './helpers/convertFormToInternal';
import { getTypesForCategory } from './helpers/getTypesForCategory';
import ThankYouText from './components/ThankYouText/ThankYouText';
import { formDetails } from './config/formDetails';
import { FormDetails, FormStyling } from './models/FormDetails';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { loadMailerLite } from './helpers/loadMailerLite';
import { IdWithLabel } from './models/IdWithLabel';
import { postMemo } from './helpers/postMemo';
import FormSection from './components/FormSection/FormSection';
import { FormSectionType } from './models/FormSectionType';
import WhatForm from './components/Forms/WhatForm/WhatForm';
import WhereForm from './components/Forms/WhereForm/WhereForm';
import MotivationForm from './components/Forms/MotivationForm/MotivationForm';
import WhenForm from './components/Forms/WhenForm/WhenForm';
import PromotionsForm from './components/Forms/PromotionsForm/PromotionsForm';
import MediaForm from './components/Forms/MediaForm/MediaForm';
import ContactInfoForm from './components/Forms/ContactInfoForm/ContactInfoForm';
import PricesForm from './components/Forms/PricesForm/PricesForm';
import CommentForm from './components/Forms/CommentForm/CommentForm';
import ContactInfoApplicantForm from './components/Forms/ContactInfoApplicantForm/ContactInfoApplicantForm';
import SmartFillForm from './components/Forms/SmartFillForm/SmartFillForm';
import ProgressBar from '@ramonak/react-progress-bar';
import CategoryForm from './components/Forms/WhatForm/CategoryForm/CategoryForm';
import LanguageNoProblem from './components/Forms/WhatForm/LanguageNoProblem/LanguageNoProblem';
import EventDescriptionsForm from './components/Forms/WhatForm/EventDescriptionsForm/EventDescriptionsForm';
import KidsFriendlyForm from './components/Forms/WhatForm/KidsFriendlyForm/KidsFriendlyForm';
import ConsentForm from './components/Forms/ContactInfoApplicantForm/ConsentForm/ConsentForm';
import ApplicantMailForm from './components/Forms/ContactInfoApplicantForm/ApplicantMailForm/ApplicantMailForm';
import Fireworks from './components/Fireworks/Fireworks';

const WIZARD_DEBUG = false;

const defaultFormOrder = [
  FormSectionType.Introduction,
  FormSectionType.What,
  // FormSectionType.Motivation,
  FormSectionType.Where,
  FormSectionType.When,
  //   FormSections.Promotions,
  FormSectionType.Media,
  FormSectionType.ContactInfo,
  FormSectionType.Prices,
  //   FormSections.Comment,
  FormSectionType.ContactInfoApplicant,
];

const formIdIsValid = (id: string | null) => {
  return id && Object.keys(formDetails).includes(id);
};

const getFormDetails = (id: string | null): FormDetails | null => {
  if (!id || !formIdIsValid(id)) {
    return null;
  }
  // @ts-ignore
  return formDetails[id];
};

const getSectionProperties = (section: FormSectionType): string[] => {
  if (WIZARD_DEBUG) {
    return [];
  }
  const categoryProperties = ['categoryOfEvent', 'categoriesOfEvent', 'types'];
  const eventDescriptions = [
    'title',
    'shortDescription',
    'addEnglishShortDescription',
    'shortDescriptionEnglish',
    'addEnglishLongDescription',
    'longDescription',
    'longDescriptionEnglish',
    'longDescriptionEnglishRequired',
  ];

  const consentPage = [
    'permissionToUseImagesAndTexts',
    'saveValuesForNextEvent',
  ];

  switch (section) {
    case FormSectionType.Introduction:
      return [];
    case FormSectionType.What:
      return [
        ...eventDescriptions,
        ...categoryProperties,
        'languageNoProblem',
        'kids',
      ];
    case FormSectionType.Category:
      return categoryProperties;
    case FormSectionType.EventDescriptions:
      return eventDescriptions;
    case FormSectionType.LanguageNoProblem:
      return ['languageNoProblem'];
    case FormSectionType.KidsFriendlyForm:
      return ['kids'];
    case FormSectionType.Motivation:
      return ['motivation'];
    case FormSectionType.Where:
      return ['locationItem', 'locationAddress', 'locationLabel'];
    case FormSectionType.When:
      return ['calendar'];
    case FormSectionType.Promotions:
      return ['promotions'];
    case FormSectionType.Media:
      return ['files'];
    case FormSectionType.ContactInfo:
      return [
        'contactUrl',
        'contactPhone',
        'contactMail',
        'contactFacebook',
        'contactTwitter',
        'contactInstagram',
      ];
    case FormSectionType.Prices:
      return ['priceElement'];
    case FormSectionType.Comment:
      return ['comment'];
    case FormSectionType.ContactInfoApplicant:
      return ['contactMailApplicant', ...consentPage];
    case FormSectionType.ApplicantMail:
      return ['contactMailApplicant'];
    case FormSectionType.Consent:
      return consentPage;
    default:
      console.error('Unknown form section', section);
      return [];
  }
};

const isPageValid = (
  errors: FieldErrors<FormValues>,
  formSection: FormSectionType
) => {
  const myFields = getSectionProperties(formSection);
  // TODO: use advanced Typescript to avoid the need for the @ts-ignore
  // @ts-ignore
  return myFields.every((field) => !errors[field]);
};

function App() {
  const { t, i18n } = useTranslation();
  const formMethods = useForm<FormValues>({
    defaultValues: {},
    mode: 'onChange',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = formMethods;

  const urlParamsStr: string = useLocation().search;
  const urlParams: URLSearchParams = new URLSearchParams(urlParamsStr);
  let formId: string | null = urlParams.get('id');
  if (!formId) {
    formId = 'iamsterdam';
  }

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);

  const [formOrder, setFormOrder] = useState<FormSectionType[]>(
    getFormDetails(formId)?.customFormSections || defaultFormOrder
  );
  const [currentFocus, setCurrentFocus] = useState<FormSectionType | null>(
    getFormDetails(formId)?.wizardStyleUse ? formOrder[0] : null
  );

  const [pageErrorActive, setPageErrorActive] = useState<boolean>(true);

  const currentSectionIsValid = async () => {
    return await formMethods.trigger(
      // TODO: use advanced Typescript to avoid the need for the @ts-ignore
      // @ts-ignore
      getSectionProperties(currentFocus)
    );
  };
  const goToNextFormSection = async () => {
    if (!currentFocus) {
      return; // no wizard style used
    }
    // Check validity of current form section
    if (!(await currentSectionIsValid())) {
      setPageErrorActive(true);
      return;
    }

    const currentIndex = formOrder.indexOf(currentFocus);
    if (currentIndex < formOrder.length - 1) {
      setCurrentFocus(formOrder[currentIndex + 1]);
    }
  };
  const goToPreviousFormSection = () => {
    if (!currentFocus) {
      return; // no wizard style used
    }
    const currentIndex = formOrder.indexOf(currentFocus);
    if (currentIndex > 0) {
      setCurrentFocus(formOrder[currentIndex - 1]);
    }
  };

  // Determine if third party script should be loaded
  const [acLoaded, setAcLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (!acLoaded && formId === 'congresagenda') {
      console.log('Loading MailerLite script...');
      loadMailerLite(() => {
        setAcLoaded(true);
      });
    }
  }, []);

  const [typesForCategories, setTypesForCategories] = useState<IdWithLabel[]>(
    []
  );

  const initForm = () => {
    const emptyExternalForm: ExternalEventItemModel = getEmptyExternalItem(
      'EVENEMENT'
    ) as ExternalEventItemModel;
    if (emptyExternalForm.calendar?.calendarType) {
      emptyExternalForm.calendar.calendarType = 'SINGLEDATES';
    }
    let emptyInternalForm: InternalItemModel =
      externalToInternal(emptyExternalForm);

    // @ts-ignore
    formMethods.reset({
      // @ts-ignore
      ...formMethods.getValues(),
      // @ts-ignore
      calendar: emptyInternalForm.calendar,
    });
  };

  const updateLang = async (lang: Lang) => {
    formMethods.setValue('formLanguage', lang);
    await i18n.changeLanguage(lang);
    console.log('Set language:', lang);
  };

  const initLang = async () => {
    if (!formIdIsValid(formId)) {
      return;
    }
    const nlFormTranslationsResponse = await fetch(`locales/nl/${formId}.json`);
    const nlFormTranslations = await nlFormTranslationsResponse.json();

    const enFormTranslationsResponse = await fetch(`locales/en/${formId}.json`);
    const enFormTranslations = await enFormTranslationsResponse.json();

    i18n.addResourceBundle('nl', 'form', nlFormTranslations);
    i18n.addResourceBundle('en', 'form', enFormTranslations);

    const urlLang: string | null = urlParams.get('lang');

    let lang: string = 'nl';
    if (urlLang && (urlLang === 'en' || urlLang === 'nl')) {
      lang = urlLang;
    }

    await updateLang(lang as Lang);
  };

  const onLangToggle = async () => {
    if (i18n.language === 'nl') {
      await updateLang('en' as Lang);
    } else {
      await updateLang('nl' as Lang);
    }
  };

  const getLocalstorageKey = () => {
    return `${formId}FormData`;
  };

  const loadFormDataFromLocalstorage = () => {
    const prevFormValuesStr: string | null =
      localStorage.getItem(getLocalstorageKey());
    if (prevFormValuesStr) {
      const prevFormValues: FormValues = JSON.parse(prevFormValuesStr);
      formMethods.reset(prevFormValues);
      if (prevFormValues.categoriesOfEvent) {
        updateCategoriesTypes(prevFormValues.categoriesOfEvent as string[]);
      }
    }
  };

  const resetPriceValues = () => {
    formMethods.resetField('priceElement.priceValue.from', {
      defaultValue: 0,
    });
    formMethods.resetField('priceElement.priceValue.until', {
      defaultValue: 0.01,
    });
  };

  const updateCategoriesTypes = (eventCategories: string[]) => {
    if (!eventCategories || eventCategories.length <= 0) {
      return;
    }

    const updatedTypesForCategories: IdWithLabel[] = eventCategories.flatMap(
      (eventCategory) =>
        getTypesForCategory(eventCategory, getFormDetails(formId), t, i18n)
    );

    const updatedTypeIdsForCategories: string[] = updatedTypesForCategories.map(
      (type) => type.id
    );

    let selectedTypeIdsForCategories: string[] | string | undefined =
      formMethods.getValues('types');
    if (
      selectedTypeIdsForCategories &&
      selectedTypeIdsForCategories.length > 0
    ) {
      if (!Array.isArray(selectedTypeIdsForCategories)) {
        selectedTypeIdsForCategories = [selectedTypeIdsForCategories];
      }

      const updatedSelectedTypeIdsForCategories: string[] =
        selectedTypeIdsForCategories.filter((selectedTypeId: string) =>
          updatedTypeIdsForCategories.includes(selectedTypeId)
        );

      // Deselect types of (now) hidden categories
      formMethods.resetField('types', {
        defaultValue: updatedSelectedTypeIdsForCategories,
      });
    }

    setTypesForCategories(updatedTypesForCategories);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type);

      if (name === 'priceElement.freeentrance') {
        const isFreeEntrance = value.priceElement?.freeentrance;
        if (isFreeEntrance) {
          resetPriceValues();
        }
      }
      if (name === 'categoryOfEvent') {
        if (value.categoryOfEvent) {
          formMethods.setValue('categoriesOfEvent', [value.categoryOfEvent]);
        }
      }

      if (name === 'categoriesOfEvent') {
        updateCategoriesTypes(value.categoriesOfEvent as string[]);
      }

      // Save form data (if permission given)
      const formData: FormValues = formMethods.getValues();
      if (formData.saveValuesForNextEvent) {
        localStorage.setItem(getLocalstorageKey(), JSON.stringify(formData));
      } else if (type) {
        localStorage.removeItem(getLocalstorageKey());
      }

      // Reset page error after any change
      setPageErrorActive(false);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // On loading new pages, hide potential error messages at start
    // These are triggered on a next/submit attempt
    setTimeout(() => {
      clearErrors();
    }, 0);
  }, [currentFocus]);

  useEffect(() => {
    initForm();
    loadFormDataFromLocalstorage();
    void initLang();
  }, [formMethods]);

  useEffect(() => {
    const formStyling: FormStyling | undefined =
      getFormDetails(formId)?.styling;
    if (!formStyling) {
      return;
    }

    for (const [key, value] of Object.entries(formStyling)) {
      const keyHyphen = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      document.documentElement.style.setProperty('--' + keyHyphen, value);

      if (key === 'brandColor') {
        document.documentElement.style.setProperty('--FF-COLOR-PRIMARY', value);
      }
      if (key === 'brandColorContrast') {
        document.documentElement.style.setProperty(
          '--FF-COLOR-PRIMARY-CONTRAST',
          value
        );
      }
    }
  }, [formDetails]);

  const [locationSelectionType, setLocationSelectionType] =
    useState<LocationSelectionType>(LocationSelectionType.RegisteredLocation);
  const getLocationSelectionType = () => {
    return locationSelectionType;
  };

  const onSubmit = async (data: any) => {
    console.log('Data', data);

    console.log('SUBMIT: Start');

    setIsSubmitting(true);
    const eventItem: InternalEventItemModel = convertFormToInternal(
      data,
      getLocationSelectionType(),
      getFormDetails(formId)
    );
    console.log('Event item', eventItem);

    await postNewEvent(eventItem, formId)
      .then(async (res) => {
        console.log('SUBMIT: Success base event');
        // setIsSubmitting(false); // If submit base event is successful, never allow another submission

        console.log(res);
        const itemId: string | undefined = res?.data?.id;

        // Post applicant information memo
        if (itemId && data.contactMailApplicant) {
          await postMemo(
            itemId,
            `Aanmelder te bereiken via: ${data.contactMailApplicant}`
          );
        }

        // Post comment memo
        if (itemId && data.comment?.trim()) {
          await postMemo(itemId, data.comment.trim());
        }

        // Post motivation memo
        if (itemId && data.motivation?.trim()) {
          await postMemo(itemId, data.motivation);
        }
        console.log('SUBMIT: Success full event');
        setIsSubmitSuccessful(true);
      })
      .catch((err) => {
        setIsSubmitting(false);
        throw new Error(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const [fireworksTriggered, setFireworksTriggered] = useState<boolean>(false);
  const [fireworksTimeout, setFireworksTimeout] =
    useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (isSubmitSuccessful) {
      setFireworksTriggered(true);
      window.scrollTo(0, 0);
      return;
    } else if (
      getValues('showMotivation') &&
      getFormDetails(formId)?.userOrganisation === 'Amsterdam Marketing'
    ) {
      setFireworksTriggered(true);
    } else {
      setFireworksTriggered(false);
    }
  }, [watch('showMotivation'), isSubmitSuccessful]);

  useEffect(() => {
    if (fireworksTriggered) {
      if (fireworksTimeout) {
        return; // Let current fireworks finish
      }
      const timeOutID = setTimeout(() => {
        setFireworksTriggered(false);
        setFireworksTimeout(null);
      }, 5000);
      setFireworksTimeout(timeOutID);
    } else {
      if (fireworksTimeout) {
        clearTimeout(fireworksTimeout);
        setFireworksTimeout(null);
      }
    }
  }, [fireworksTriggered]);

  const getFormSectionElement = (formSection: FormSectionType) => {
    switch (formSection) {
      case FormSectionType.Introduction:
        return (
          <>
            <InstructionsText />
            {getFormDetails(formId)?.showSmartFill && (
              <SmartFillForm formMethods={formMethods} />
            )}
          </>
        );
      case FormSectionType.What:
        return (
          <WhatForm
            formMethods={formMethods}
            typesForCategory={typesForCategories}
            formDetails={getFormDetails(formId)}
          />
        );
      case FormSectionType.Category:
        return (
          <CategoryForm
            formDetails={getFormDetails(formId)}
            formMethods={formMethods}
            typesForCategory={typesForCategories}
            isStandalone
          />
        );
      case FormSectionType.Where:
        return (
          <WhereForm
            register={register}
            formMethods={formMethods}
            control={control}
            errors={errors}
            locationSelectionType={locationSelectionType}
            setLocationSelectionType={setLocationSelectionType}
            userOrganisation={getFormDetails(formId)?.userOrganisation}
            locationSelectParams={getFormDetails(formId)?.locationSelectParams}
          />
        );
      case FormSectionType.LanguageNoProblem:
        return (
          <LanguageNoProblem
            formDetails={getFormDetails(formId)}
            formMethods={formMethods}
            isStandalone
          />
        );
      case FormSectionType.KidsFriendlyForm:
        return <KidsFriendlyForm formMethods={formMethods} isStandalone />;
      case FormSectionType.EventDescriptions:
        return (
          <EventDescriptionsForm
            formMethods={formMethods}
            formDetails={getFormDetails(formId)}
            isStandalone
          />
        );
      case FormSectionType.Motivation:
        return (
          <MotivationForm
            register={register}
            errors={errors}
            formDetails={getFormDetails(formId)}
            watch={watch}
            setValue={setValue}
          />
        );
      case FormSectionType.When:
        return (
          <WhenForm
            formMethods={formMethods}
            control={control}
            errors={errors}
            locationSelectionType={getLocationSelectionType()}
            WhenFormConfig={getFormDetails(formId)?.whenInfo}
          />
        );
      case FormSectionType.Promotions:
        return (
          <PromotionsForm
            formMethods={formMethods}
            errors={errors}
            lang={i18n.language as Lang}
          />
        );
      case FormSectionType.Media:
        return (
          <MediaForm
            register={register}
            formMethods={formMethods}
            control={control}
            errors={errors}
            formDetails={getFormDetails(formId)}
          />
        );
      case FormSectionType.ContactInfo:
        return (
          <ContactInfoForm
            register={register}
            errors={errors}
            config={getFormDetails(formId)?.contactInfo}
          />
        );
      case FormSectionType.Prices:
        return (
          <PricesForm
            register={register}
            formMethods={formMethods}
            control={control}
            errors={errors}
          />
        );
      case FormSectionType.Comment:
        return (
          <CommentForm
            register={register}
            errors={errors}
            formDetails={getFormDetails(formId)}
            watch={watch}
          />
        );
      case FormSectionType.ContactInfoApplicant:
        return (
          <ContactInfoApplicantForm
            register={register}
            formMethods={formMethods}
            control={control}
            errors={errors}
          />
        );
      case FormSectionType.Consent:
        return <ConsentForm formMethods={formMethods} isStandalone />;
      case FormSectionType.ApplicantMail:
        return <ApplicantMailForm formMethods={formMethods} isStandalone />;
    }
  };

  // console.warn("ERRORS", errors); // DEBUG

  return (
    <HelmetProvider>
      <Helmet>
        {getFormDetails(formId)?.meta?.faviconUrl && (
          <link rel='icon' href={getFormDetails(formId)?.meta?.faviconUrl} />
        )}

        {getFormDetails(formId)?.meta?.description && (
          <meta
            name='description'
            content={getFormDetails(formId)?.meta?.description}
          />
        )}

        {getFormDetails(formId)?.meta?.title && (
          <title>{getFormDetails(formId)?.meta?.title}</title>
        )}
      </Helmet>
      {!formIdIsValid(formId) && (
        <p className={classes.formError}>Onbekend formulier ID meegegeven</p>
      )}

      {fireworksTriggered && <Fireworks />}

      {formIdIsValid(formId) && (
        <div className={classes.main}>
          <header>
            {!getFormDetails(formId)?.hideLanguageSelect && (
              <button className={'btn--lang-select'} onClick={onLangToggle}>
                <Twemoji>{i18n.language === Lang.NL ? '🇬🇧' : '🇳🇱'}</Twemoji>
              </button>
            )}

            <img
              className={classes.logo}
              alt={'Logo'}
              src={'/images/' + t('form:logo')}
            />
          </header>

          {getFormDetails(formId)?.showAmsGarland &&
            (currentFocus === FormSectionType.Introduction ||
              currentFocus === FormSectionType.Motivation) && (
              <div className={classes.amsGarland}>
                <img src={'/images/AMS750_Slingers-01.jpg'} alt={''} />
              </div>
            )}

          {!isSubmitSuccessful && (
            <form
              onSubmit={(event) =>
                handleSubmit(onSubmit)(event).catch((error) => {
                  alert(error.message);
                })
              }>
              {getFormDetails(formId)?.wizardStyleUse &&
                currentFocus &&
                currentFocus > 1 && (
                  <ProgressBar
                    className={classes.progressBar}
                    completed={Math.round(
                      (formOrder.indexOf(currentFocus) / formOrder.length) * 100
                    )}
                    borderRadius={'var(--border-radius, 10px)'}
                    margin={'0 0 20px 0'}
                    bgColor={'var(--FF-COLOR-PRIMARY, #ff0000)'}
                  />
                )}
              {formOrder.map((formSection) => {
                return (
                  <FormSection
                    key={formSection}
                    active={
                      !getFormDetails(formId)?.wizardStyleUse ||
                      !currentFocus ||
                      currentFocus === formSection
                    }>
                    {getFormSectionElement(formSection)}
                  </FormSection>
                );
              })}

              <div className={classes.buttonContainer}>
                {currentFocus && currentFocus !== formOrder[0] ? (
                  <button type='button' onClick={goToPreviousFormSection}>
                    {t('form:previous')}
                  </button>
                ) : (
                  <span></span>
                )}
                <div>
                  {pageErrorActive && (
                    <span>{t('form:requiredFieldsMissing')}</span>
                  )}
                  {currentFocus &&
                  !(currentFocus === formOrder[formOrder.length - 1]) ? (
                    <button
                      type='button'
                      onClick={goToNextFormSection}
                      disabled={pageErrorActive}>
                      {t('form:next')}
                    </button>
                  ) : (
                    <div className={classes.submitContainer}>
                      <input
                        type='submit'
                        value={
                          !isSubmitting
                            ? (t('form:submitEventToRemote') as string)
                            : (t('form:submittingEventToRemote') as string)
                        }
                        disabled={pageErrorActive || isSubmitting}
                      />
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
          {isSubmitSuccessful && (
            <div className={classes.submitSuccess}>
              <ThankYouText />
            </div>
          )}
        </div>
      )}
    </HelmetProvider>
  );
}

export default withRouter(App);
