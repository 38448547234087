import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../models/FormValues";
import FormSection from "../FormSection/FormSection";
import { FormDetails } from "../../../models/FormDetails";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import ReactMarkdown from "react-markdown";

const CommentForm = (props: {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<any>;
  formDetails: FormDetails | null;
  watch: UseFormWatch<FormValues>;
}) => {
  const { t } = useTranslation();

  return (
    <FormSection headingTitle={t("form:comment")}>
      <>
        <ReactMarkdown linkTarget="_blank">
          {t("form:commentDescription")}
        </ReactMarkdown>
        <textarea
          placeholder={
            props.formDetails?.hidePlaceholders &&
            props.formDetails.hidePlaceholders["comment"]
              ? ""
              : t("form:commentPlaceholder")
          }
          {...props.register("comment", {
            required: false,
          })}
        />
      </>
    </FormSection>
  );
};
export default CommentForm;
