import React from "react";
import classes from "../../../App.module.scss";

const FormSection = (props: {
  headingTitle: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <section>
      <h2 className={classes.heading}>{props.headingTitle}</h2>
      <div className={`${classes.body} ${props.className}`}>
        {props.children}
      </div>
    </section>
  );
};
export default FormSection;
