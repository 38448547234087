import React, { useEffect, useState } from "react";
import classes from "../../../App.module.scss";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { FormValues } from "../../../models/FormValues";
import FormInput from "../../Inputs/FormInput/FormInput";
import { websitePattern } from "../../../helpers/constants";
import { useTranslation } from "react-i18next";
import FormSection from "../FormSection/FormSection";
import { ContactInfoFormConfig } from "../../../models/ContactInfoFormConfig";

const defaultContactInfoFormConfig: ContactInfoFormConfig = {
  customItems: [
    // { type: "phoneNumber", required: false },
    // { type: "email", required: false },
    { type: "website", required: true },
    { type: "ticketLink", required: false },
    { type: "facebook", required: false },
    { type: "x", required: false },
    { type: "instagram", required: false },
  ],
};

// TODO: Add correct error message for social media links when they are required and not filled in
// Currently that case is not applicable for any client
const ContactInfoForm = (props: {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  config: ContactInfoFormConfig | undefined;
}) => {
  const { t } = useTranslation();

  const myConfig = props.config ?? defaultContactInfoFormConfig;

  const [errors, setErrors] = useState(props.errors);
  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  return (
    <FormSection headingTitle={t("form:eventContactInfo")}>
      <p>{t("form:willBeShownOnline")}</p>

      {myConfig?.customItems?.map((item) => {
        switch (item.type) {
          case "phoneNumber":
            return (
              <div className={classes.category} key={item.type}>
                <label>
                  {t("form.phoneNumber")}
                  {item.required ? " (*)" : ""}
                </label>
                <FormInput
                  register={props.register}
                  type="text"
                  registrationName="contactPhone"
                  required={item.required}
                />

                {errors.contactPhone &&
                  (errors.contactPhone.type === "required" ? (
                    <div className={classes.formError}>
                      {t("form.phoneRequired")}
                    </div>
                  ) : (
                    <div className={classes.formError}>
                      {t("form.phoneInvalid")}
                    </div>
                  ))}
              </div>
            );
          case "email":
            return (
              <div className={classes.category} key={item.type}>
                <label>E-mail{item.required ? " (*)" : ""}</label>
                <FormInput
                  register={props.register}
                  type="text"
                  registrationName="contactMail"
                  required={item.required}
                  pattern={/^\S+@\S+$/i}
                />

                {errors.contactMail &&
                  (errors.contactMail.type === "required" ? (
                    <div className={classes.formError}>
                      {t("form.emailRequired")}
                    </div>
                  ) : (
                    <div className={classes.formError}>
                      {t("form.emailInvalid")}
                    </div>
                  ))}
              </div>
            );
          case "website":
            return (
              <div className={classes.category} key={item.type}>
                <label>
                  Website
                  {item.required ? " (*)" : ""}
                </label>
                <FormInput
                  pattern={websitePattern}
                  register={props.register}
                  type="text"
                  registrationName="contactUrl"
                  required={item.required ?? false}
                  placeholder={"https://"}
                />

                {errors.contactUrl &&
                  (errors.contactUrl.type === "required" ? (
                    <div className={classes.formError}>
                      {t("form.websiteRequired")}
                    </div>
                  ) : (
                    <div className={classes.formError}>
                      {t("form.urlIncorrect")}
                    </div>
                  ))}
              </div>
            );
          case "ticketLink":
            return (
              <div className={classes.category} key={item.type}>
                <label>
                  Ticket link
                  {item.required ? " (*)" : ""}
                </label>
                <FormInput
                  pattern={websitePattern}
                  register={props.register}
                  type="text"
                  registrationName="ticketUrl"
                  required={item.required ?? false}
                  placeholder={"https://"}
                />

                {errors.ticketUrl &&
                  (errors.ticketUrl.type === "required" ? (
                    <div className={classes.formError}>
                      {t("form.ticketLinkRequired")}
                    </div>
                  ) : (
                    <div className={classes.formError}>
                      {t("form.urlIncorrect")}
                    </div>
                  ))}
              </div>
            );
          case "facebook":
            return (
              <div className={classes.category} key={item.type}>
                <label>Facebook{item.required ? " (*)" : ""}</label>
                <FormInput
                  register={props.register}
                  type="text"
                  registrationName="contactFacebook"
                  required={item.required}
                  pattern={websitePattern}
                  placeholder="https://"
                />

                {errors.contactFacebook && (
                  <div className={classes.formError}>
                    {t("form.urlIncorrect")}
                  </div>
                )}
              </div>
            );
          case "x":
            return (
              <div className={classes.category} key={item.type}>
                <label>X/Twitter{item.required ? " (*)" : ""}</label>
                <FormInput
                  register={props.register}
                  type="text"
                  registrationName="contactTwitter"
                  required={item.required}
                  pattern={websitePattern}
                  placeholder="https://"
                />

                {errors.contactTwitter && (
                  <div className={classes.formError}>
                    {t("form.urlIncorrect")}
                  </div>
                )}
              </div>
            );
          case "instagram":
            return (
              <div className={classes.category} key={item.type}>
                <label>Instagram{item.required ? " (*)" : ""}</label>
                <FormInput
                  register={props.register}
                  type="text"
                  registrationName="contactInstagram"
                  required={item.required}
                  pattern={websitePattern}
                  placeholder="https://"
                />

                {errors.contactInstagram && (
                  <div className={classes.formError}>
                    {t("form.urlIncorrect")}
                  </div>
                )}
              </div>
            );
        }
      })}
    </FormSection>
  );
};
export default ContactInfoForm;
