import React, { useEffect } from "react";
import classes from "../../../../App.module.scss";
import { Controller, UseFormReturn } from "react-hook-form";
import CheckboxSelector from "../../../Inputs/CheckboxSelector/CheckboxSelector";
import RadioButtonSelector from "../../../Inputs/RadioButtonSelector/RadioButtonSelector";
import { IdWithLabel } from "../../../../models/IdWithLabel";
import { getCategoryWithLabel } from "../../../../helpers/getCategoryWithLabel";
import { FormDetails } from "../../../../models/FormDetails";
import { FormValues } from "../../../../models/FormValues";
import { useTranslation } from "react-i18next";
import FormSection from "../../FormSection/FormSection";

const CategoryForm = (props: {
  formDetails: FormDetails | null;
  formMethods: UseFormReturn<FormValues>;
  typesForCategory: IdWithLabel[];
  isStandalone?: boolean;
}) => {
  const { t, i18n } = useTranslation();

  let categoryOptions: IdWithLabel[] = [];
  if (props?.formDetails?.categories) {
    categoryOptions = Object.keys(props.formDetails.categories).map(
      (categoryId) => getCategoryWithLabel(categoryId, t, i18n),
    );
  }

  let tagOptions: IdWithLabel[] = [];
  if (props?.formDetails?.tags) {
    tagOptions = props.formDetails.tags;
  }

  useEffect(() => {
    props.formMethods.clearErrors("types");
  }, [props.typesForCategory]);

  const categoryForm = (
    <div className={classes.category}>
      {categoryOptions.length > 0 && (
        <>
          {!props.isStandalone && <label>{t("event.category")} (*)</label>}
          <p className={classes.categoryDescription}>
            {t("form:categoryDescription")}
          </p>

          {props?.formDetails?.allowSelectingMultipleCategories ? (
            <Controller
              name={"categoriesOfEvent"}
              control={props.formMethods.control}
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <>
                  <CheckboxSelector
                    name={"categoryOfEvent"}
                    options={categoryOptions}
                    onChange={onChange}
                    value={value}
                  />

                  {error && (
                    <div className={classes.formError}>
                      {t("form.categoryRequired")}
                    </div>
                  )}
                </>
              )}
            />
          ) : (
            <Controller
              name={"categoryOfEvent"}
              control={props.formMethods.control}
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <>
                  <RadioButtonSelector
                    name={"categoryOfEvent"}
                    options={categoryOptions}
                    onChange={onChange}
                    value={value}
                  />

                  {error && (
                    <div className={classes.formError}>
                      {t("form.categoryRequired")}
                    </div>
                  )}
                </>
              )}
            />
          )}

          {props.typesForCategory.length !== 0 && (
            <>
              <label>{t("form:subcategories")}</label>
              {!props.formDetails?.singleTypeSelect ? (
                <Controller
                  name={"types"}
                  control={props.formMethods.control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <>
                      <CheckboxSelector
                        name={"categoryOfEvent"}
                        options={props.typesForCategory}
                        onChange={onChange}
                        value={value || []}
                      />

                      {error && (
                        <div className={classes.formError}>
                          {t("form.typeRequired")}
                        </div>
                      )}
                    </>
                  )}
                />
              ) : (
                <Controller
                  name={"types"}
                  control={props.formMethods.control}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <>
                      <RadioButtonSelector
                        name={"types"}
                        options={props.typesForCategory}
                        onChange={(val) => onChange(val ? [val] : [])}
                        value={value?.[0] || null}
                      />

                      {error && (
                        <div className={classes.formError}>
                          {t("form.typeRequired")}
                        </div>
                      )}
                    </>
                  )}
                />
              )}
            </>
          )}
        </>
      )}

      {tagOptions.length > 0 && (
        <>
          <label>{t("form:tags")} (*)</label>
          <p className={classes.tagsDescription}>{t("form:tagsDescription")}</p>
          <Controller
            name={"tagsOfEvent"}
            control={props.formMethods.control}
            rules={{
              required: true,
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <>
                <RadioButtonSelector
                  name={"types"}
                  options={tagOptions}
                  onChange={(val) => onChange(val ? [val] : [])}
                  value={value?.[0] || null}
                />

                {error && (
                  <div className={classes.formError}>
                    {t("form:tagsRequired")}
                  </div>
                )}
              </>
            )}
          />
        </>
      )}
    </div>
  );

  if (!props.isStandalone) {
    return categoryForm;
  } else {
    return (
      <FormSection headingTitle={t("form:categoryHeader")}>
        {categoryForm}
      </FormSection>
    );
  }
};

export default CategoryForm;
