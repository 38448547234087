import React from "react";
import { FieldErrors, UseFormReturn } from "react-hook-form";
import { FormValues } from "../../../models/FormValues";
import FormSection from "../FormSection/FormSection";
import { useTranslation } from "react-i18next";
import PromotionsWidget from "@feed-factory/feed-factory.promotions-widget";
import { Lang } from "@feed-factory/feed-factory.models.lang";
import classes from "../../../App.module.scss";
import promotionClasses from "./PromotionsForm.module.scss";

const myPermissions = {
  accessType: "local.editor",
  generalPermission: "write",
  mayModifyPublishedItems: true,
};

const PromotionsForm = (props: {
  formMethods: UseFormReturn<FormValues>;
  errors: FieldErrors<FormValues>;
  lang: Lang;
}) => {
  const { t } = useTranslation();

  // Process errors
  let errorMessage = "";
  if (props.errors.promotions && Array.isArray(props.errors.promotions)) {
    props.errors.promotions.map((errorObject) => {
      // Check if there are any required errors
      // If so, show the user a specified error message
      // If other errors, unknown case -> show generic error message
      let hasAnyRequiredError = false;
      for (let [key, error] of Object.entries(errorObject)) {
        const err: any = error;
        if (err.type === "required") {
          hasAnyRequiredError = true;
          break;
        }
      }
      if (hasAnyRequiredError) {
        errorMessage = `${t("form:promotionSpecsRequired")}`;
      } else {
        errorMessage = `${t("form:promotionSpecsInvalid")}`;
      }
    });
  }

  return (
    <FormSection
      headingTitle={t("form.promotions")}
      className={promotionClasses.PromotionsForm}
    >
      <PromotionsWidget
        allowCreationByTemplate
        formMethods={props.formMethods}
        namespace={"promotions"}
        lang={props.lang}
        // @ts-ignore (permission object not recognised as string literal))
        myPermissions={myPermissions}
        entityType={"EVENEMENT"}
        creationAllowed={"templateOnly"}
      />
      {errorMessage && (
        <div className={classes.formError} style={{ marginTop: "10px" }}>
          {errorMessage}
        </div>
      )}
    </FormSection>
  );
};

export default PromotionsForm;
